import React, { useEffect, useState, useRef } from 'react'
import '.././App.css';
import Swal from 'sweetalert2';
import { auth, db } from '../firebase';
import { collection, addDoc, Timestamp, query, orderBy, onSnapshot, deleteDoc, doc, setDoc, where } from 'firebase/firestore'

let propid = localStorage.getItem('propid');

export default function Purchaseproduct() {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });
    const [userData, setUserData] = useState();
    const [productCat, setproductCat] = useState([]);
    const [searchText, setsearchText] = useState("");
    const [products, setproducts] = useState([]);
    const [prodname, setprodname] = useState("");


    const [updocid, setupdocid] = useState("");
    const [upprodname, setupprodname] = useState("");



    useEffect(() => {
        if (searchText === '') {
            fetchData1()
        } else {
            fetchData2()
        }
    }, [searchText]);



    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            setUserData(user);
        });
    });

    const fetchData1 = async () => {
        try {

            const q1 = query(collection(db, 'purchaseproducts'), where('propid', '==', propid), orderBy("prodname", "asc"))
            onSnapshot(q1, (querySnapshot2) => {
                setproducts(querySnapshot2.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data(),
                })));
            });

        } catch (error) {
            Toast.fire({
                icon: "error",
                title: error.message
            });
        }
    }

    const fetchData2 = async () => {
        try {
            if (productCat) {
                const filteredData = products.filter((item) =>
                    item.data.prodname.toUpperCase().includes(searchText.toUpperCase())
                );
                setproducts(filteredData);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }


    const handleSubmit = async () => {
        if (!prodname) {
            Toast.fire({
                icon: "error",
                title: 'Please fill all the fields!!'
            });
            return;
        }

        await addDoc(collection(db, 'purchaseproducts'), {
            propid: propid,
            prodname: prodname,
            uid: userData.uid,
            created: Timestamp.now()
        });

        setprodname("");

        Toast.fire({
            icon: "success",
            title: 'Product Added Successfully!!'
        });
    }

    const handleDelete = async (docid) => {
        const taskDocRef = doc(db, 'purchaseproducts', docid)
        await deleteDoc(taskDocRef)
        Toast.fire({
            icon: "success",
            title: 'Product Deleted Successfully!!'
        });
        fetchData1();
    }

    const handleEdit = (docid, prodname) => {
        setupdocid(docid);
        setupprodname(prodname);
    }


    const handleUpdate = async () => {
        if (!upprodname) {
            Toast.fire({
                icon: "error",
                title: 'Please fill all the fields!!'
            });
            return;
        }

        try {
            await setDoc(doc(db, "purchaseproducts", updocid), {
                prodname: upprodname,
                propid: propid,
                uid: userData.uid,
                created: Timestamp.now()
            });

            Toast.fire({
                icon: "success",
                title: 'Product Updated Successfully!!'
            });

            fetchData1();
        } catch (error) {
            console.log(error.message);
        }

    }




    return (
        <div>
            <div className="content-wrapper">
                <br />
                <section className="content">
                    <div className="container-fluid">
                        <button className="btn btn-secondary " style={{ float: "right" }} >|| Purchase Products ||</button>
                        <button className="btn btn-info btn-sm" data-toggle="modal" data-target="#insert-modal">Add Product</button><br /><br />
                        <div className="card card-primary card-outline ex1">
                            <div className=" p-1">
                                &nbsp;&nbsp;<input type="search" value={searchText} onChange={(e) => setsearchText(e.target.value)}
                                    className='form-control col-sm-4' placeholder='Type here......' />&nbsp;
                            </div>
                            <div className="card-body table-responsive p-0" >
                                <table className="table table-bordered table-hover table-striped table-head-fixed">
                                    <thead>
                                        <tr>
                                            <th>Sr No</th>
                                            <th>Product Name</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            products.map((item, num = 1) =>
                                                <tr key={num + 1}>
                                                    <td width="80">{num + 1}</td>
                                                    <td>{item.data.prodname} </td>

                                                    <td>
                                                        <a onClick={() => handleEdit(item.id, item.data.prodname)} href='#0' data-toggle="modal" data-target="#text-modal1" className="btn btn-sm btn-success" > <i className="fa fa-edit"></i></a>&nbsp;
                                                        <a onClick={() => { if (window.confirm('Are u sure?')) { handleDelete(item.id) }; }} className="btn btn-sm btn-danger" href='#0' > <i className="fa fa-trash"></i></a>&nbsp;
                                                    </td>
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* ============================================= modal start ================================================= */}

            <div className="modal fade" id="insert-modal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Add Product</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>  Product Name</label>
                                    <input type="text" value={prodname} onChange={(e) => setprodname(e.target.value)} className="form-control" />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="submit" onClick={() => handleSubmit()} className="btn btn-primary" data-dismiss="modal">Save </button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal fade" id="text-modal1">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Edit Product</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-6">
                                    <label> Product Name</label>
                                    <input type="text" value={upprodname} onChange={(e) => setupprodname(e.target.value)} className="form-control" placeholder="Enter  Item Name ..." />
                                </div>

                            </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" onClick={() => handleUpdate()} className="btn btn-primary" data-dismiss="modal">Update </button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
