import React, { useEffect, useState, useRef } from 'react'
import '.././App.css';
import Swal from 'sweetalert2';
import { auth, db } from '../firebase';
import { collection, addDoc, Timestamp, query, orderBy, onSnapshot, getDoc, getDocs, deleteDoc, doc, setDoc, where } from 'firebase/firestore'
import Select from 'react-select'

let propid = localStorage.getItem('propid');
export default function Customers() {
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });
  const [userData, setUserData] = useState();
  const [restocustomers, setrestocustomers] = useState([]);
  const [searchText, setsearchText] = useState("");
  const [name, setname] = useState("");
  const [mobile, setmobile] = useState("");
  const [address, setaddress] = useState("");
  const [email, setemail] = useState("");
  const [total, settotal] = useState(0);
  const [bookings, setbookings] = useState([]);
  const [bookingData, setbookingData] = useState("");
  const [bookingId, setbookingId] = useState("");

  const [updocid, setupdocid] = useState("");
  const [upname, setupname] = useState("");
  const [upmobile, setupmobile] = useState("");
  const [upaddress, setupaddress] = useState("");
  const [upemail, setupemail] = useState("");
  const [uptotal, setuptotal] = useState(0);

  useEffect(() => {
    if (searchText === '') {
      fetchData1()
    } else {
      fetchData2()
    }
  }, [searchText]);



  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setUserData(user);
    });
  });

  const fetchData1 = async () => {
    try {

      const q3 = query(collection(db, 'bookings'), orderBy("createdAt", "asc"), where('status', '==', 'Booked'))
      onSnapshot(q3, async (querySnapshot1) => {
        const combinedData1 = [];
        for (const doc1 of querySnapshot1.docs) {
          for (let i = 0; i < doc1.data().rooms.length; i++) {
            combinedData1.push({
              id: doc1.id,
              data: doc1.data(),
              label: doc1.data().rooms[i].roomname + " / " + doc1.data().customers.custname,
              value: doc1.id,
            });
          }
        }
        setbookings(combinedData1);
      });


      const q = query(collection(db, 'restocustomers'))
      onSnapshot(q, (querySnapshot) => {
        setrestocustomers(querySnapshot.docs.map(doc => ({
          id: doc.id,
          data: doc.data()
        })));
      });
    } catch (error) {
      Toast.fire({
        icon: "error",
        title: error.message
      });
    }
  }

  const fetchData2 = async () => {
    try {
      const filteredData = restocustomers.filter((item) =>
        item.data.name.toUpperCase().includes(searchText.toUpperCase())
      );
      restocustomers(filteredData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }



  const handleSubmit = async () => {
    await addDoc(collection(db, 'restocustomers'), {
      bookingId: bookingId,
      name: name,
      mobile: mobile,
      address: address,
      propid: propid,
      total: parseFloat(total),
      paid: 0,
      email: email,
      remaining: parseFloat(total),
      uid: userData.uid,
      created: Timestamp.now()
    });
    setname(""); setmobile(""); setaddress("");
    Toast.fire({
      icon: "success",
      title: 'Customer Added Successfully!!'
    });
  }

  const handleDelete = async (docid) => {
    console.log(docid);
    const taskDocRef = doc(db, 'restocustomers', docid)
    await deleteDoc(taskDocRef)
    Toast.fire({
      icon: "success",
      title: 'Customer Deleted Successfully!!'
    });
    fetchData1();
  }

  const handleEdit = (docid, name, mobile, address, email, outstanding) => {
    setupdocid(docid);
    setupname(name);
    setupmobile(mobile);
    setupaddress(address);
    setupemail(email);
    setuptotal(outstanding);
  }

  const handleUpdate = async () => {
    await setDoc(doc(db, "restocustomers", updocid), {
      bookingId: bookingId,
      name: upname,
      mobile: upmobile,
      address: upaddress,
      propid: propid,
      total: parseFloat(uptotal),
      paid: 0,
      email: upemail,
      remaining: parseFloat(uptotal),
      uid: userData.uid,
      created: Timestamp.now()
    });
    Toast.fire({
      icon: "success",
      title: 'Customer Updated Successfully!!'
    });

    fetchData1();
  }


  const handleSelectItem1 = async (item) => {
    setbookingData(item);
    setbookingId(item.id);
    setname(item.data.customers.custname)
    setmobile(item.data.customers.mobile)
    setaddress(item.data.customers.city)
    setemail(item.data.customers.email)
  };

  const handleSelectItem2 = async (item) => {
    setbookingData(item);
    setbookingId(item.id);
    setupname(item.data.customers.custname)
    setupmobile(item.data.customers.mobile)
    setupaddress(item.data.customers.city)
    setupemail(item.data.customers.email)
  };

  return (
    <div>
      <div className="content-wrapper">
        <br />
        <section className="content">
          <div className="container-fluid">
            <button className="btn btn-secondary " style={{ float: "right" }} >|| Customer  Mgmt||</button>
            <button className="btn btn-info btn-sm" data-toggle="modal" data-target="#insert-modal">Add Customer</button><br /><br />
            <div className="card card-primary card-outline ex1">
              <div className=" p-1">
                &nbsp;&nbsp;<input type="search" value={searchText} onChange={(e) => setsearchText(e.target.value)}
                  className='form-control col-sm-4' placeholder='Type here......' />&nbsp;
              </div>
              <div className="card-body table-responsive p-0" >
                <table className="table table-bordered table-hover table-striped table-head-fixed">
                  <thead>
                    <tr>
                      <th>Sr No</th>
                      <th>Customer Name</th>
                      <th>Mobile</th>
                      <th>Address</th>
                      <th>Email</th>
                      <th> Remaining Amount</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      restocustomers.map((item, num = 1) =>
                        <tr key={num + 1}>
                          <td width="80">{num + 1}</td>
                          <td>{item.data.name}</td>
                          <td>{item.data.mobile}</td>
                          <td>{item.data.address}</td>
                          <td>{item.data.email}</td>
                          <td>{item.data.remaining}</td>
                          <td>
                            <a onClick={() => handleEdit(item.id, item.data.name, item.data.mobile, item.data.address, item.data.email, item.data.total)} href='#0' data-toggle="modal" data-target="#text-modal1" className="btn btn-sm btn-success" > <i className="fa fa-edit"></i></a>&nbsp;
                            <a onClick={() => { if (window.confirm('Are u sure?')) { handleDelete(item.id) }; }} className="btn btn-sm btn-danger" href='#0' > <i className="fa fa-trash"></i></a>
                          </td>
                        </tr>
                      )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* ============================================= modal start ================================================= */}

      <div className="modal fade" id="insert-modal">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Add Customer</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span></button>
            </div>
            <div className="modal-body">
              <div className='row'>
                <div className='col-sm-6'>
                  <label>  Select Bookings</label>
                  <Select options={bookings} value={bookingData} onChange={handleSelectItem1} />
                </div>
              </div><br />
              <div className="row">
                <div className="col-sm-6">
                  <label>  Customer</label>
                  <input type="text" value={name} onChange={(e) => setname(e.target.value)} className="form-control" />
                </div>
                <div className="col-sm-6">
                  <label>Mobile</label>
                  <input type="text" value={mobile} onChange={(e) => setmobile(e.target.value)} className="form-control" />
                </div>
                <div className="col-sm-6 mt-2">
                  <label>Address</label>
                  <input type="text" value={address} onChange={(e) => setaddress(e.target.value)} className="form-control" />
                </div>
                <div className="col-sm-6 mt-2">
                  <label>Email </label>
                  <input type="text" value={email} onChange={(e) => setemail(e.target.value)} className="form-control" />
                </div>

              </div><br />
            </div>
            <div className="modal-footer justify-content-between">
              <button type="button" onClick={() => handleSubmit()} className="btn btn-primary" data-dismiss="modal">Save </button>
              <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>


      <div className="modal fade" id="text-modal1">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Edit Customer</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span></button>
            </div>
            <div className="modal-body">
              <div className='row'>
                <div className='col-sm-6'>
                  <label>  Select Bookings</label>
                  <Select options={bookings} value={bookingData} onChange={handleSelectItem2} />
                </div>
              </div><br />
              <div className="row">
                <div className="col-sm-6">
                  <label> Customer</label>
                  <input type="text" value={upname} onChange={(e) => setupname(e.target.value)} className="form-control" placeholder="Enter  Item Name ..." />
                </div>
                <div className="col-sm-6">
                  <label>Mobile</label>
                  <input type="text" value={upmobile} onChange={(e) => setupmobile(e.target.value)} className="form-control" />
                </div>
                <div className="col-sm-6 mt-2">
                  <label>Address</label>
                  <input type="text" value={upaddress} onChange={(e) => setupaddress(e.target.value)} className="form-control" />
                </div>
                <div className="col-sm-6 mt-2">
                  <label>Email</label>
                  <input type="text" value={upemail} onChange={(e) => setupemail(e.target.value)} className="form-control" />
                </div>

              </div><br />
            </div>
            <div className="modal-footer justify-content-between">
              <button type="button" onClick={() => handleUpdate()} className="btn btn-primary" data-dismiss="modal">Update </button>
              <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
