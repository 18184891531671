import React, { useEffect, useState, useRef } from 'react'
import Swal from 'sweetalert2';
import { collection, addDoc, Timestamp, query, orderBy, onSnapshot, getDoc, getDocs, deleteDoc, doc, setDoc, getAggregateFromServer, count, updateDoc, datalimit, limit, startAfter } from 'firebase/firestore'
import { auth, db } from '../../firebase';
import '../../App.css'

export default function Addresto() {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });
    const [datalimit, setdatalimit] = useState(10);

    const [userData, setUserData] = useState();
    const [searchText, setsearchText] = useState("");
    const [restaurents, setrestaurents] = useState([]);

    const [restocat, setrestocat] = useState("Resto");
    const [restoname, setrestoname] = useState("");
    const [address, setaddress] = useState("");
    const [mobile, setmobile] = useState("");
    const [email, setemail] = useState("");
    const [tableshifting, settableshifting] = useState("");

    const [updocid, setupdocid] = useState("");
    const [uprestocat, setuprestocat] = useState("");
    const [uprestoname, setuprestoname] = useState("");
    const [upaddress, setupaddress] = useState("");
    const [upmobile, setupmobile] = useState("");
    const [upemail, setupemail] = useState("");
    const [uptableshifting, setuptableshifting] = useState("");


    useEffect(() => {
        if (searchText === '') {
            fetchData1()
        } else {
            fetchData2()
        }
    }, [searchText]);



    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            setUserData(user);
        });
    });

    const fetchData1 = async () => {
        try {
            const q1 = query(collection(db, 'restaurents'), orderBy('restoname', 'asc'))
            onSnapshot(q1, async (querySnapshot1) => {
                const combinedData = [];
                for (const doc1 of querySnapshot1.docs) {
                    combinedData.push({
                        id: doc1.id,
                        data: doc1.data(),
                    });
                }
                setrestaurents(combinedData);
            });


        } catch (error) {
            Toast.fire({
                icon: "error",
                title: error.message
            });
        }
    }

    const fetchData2 = async () => {
        try {
            const filteredData = restaurents.filter((item) =>
                item.data.restoname.toUpperCase().includes(searchText.toUpperCase())
            );
            setrestaurents(filteredData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        var pattern = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i);
        if (!pattern.test(mobile)) {
            Toast.fire({ icon: "error", title: 'Please Enter Number Only!' });
            return;
        } else if (mobile.length !== 10) {
            Toast.fire({ icon: "error", title: 'Please enter valid  Mobile Number.' });
            return;
        }


        if (!restoname || !restocat) {
            Toast.fire({
                icon: "error",
                title: 'Please fill all the fields!!'
            });
            return;
        }


        await addDoc(collection(db, 'restaurents'), {
            restocat: restocat,
            restoname: restoname,
            mobile: mobile,
            address: address,
            email: email,
            tableshifting: tableshifting,
            uid: userData.uid,
            created: Timestamp.now()
        });


        setrestoname("");
        setmobile("");
        setaddress("");
        setemail("");
        Toast.fire({
            icon: "success",
            title: 'Restaurent Added Successfully!!'
        });
    }

    const handleDelete = async (docid) => {
        const taskDocRef = doc(db, 'restaurents', docid)
        await deleteDoc(taskDocRef)
        Toast.fire({
            icon: "success",
            title: 'Restaurent Deleted Successfully!!'
        });
        fetchData1();
    }

    const handleEdit = (id, restocat, restoname, mobile, address, email, tableshifting) => {
        setupdocid(id);
        setuprestoname(restoname);
        setuprestocat(restocat);
        setupmobile(mobile);
        setupaddress(address)
        setupemail(email)
        setuptableshifting(tableshifting)
    }


    const handleUpdate = async (e) => {
        e.preventDefault();
        if (!uprestoname || !upmobile) {
            Toast.fire({
                icon: "error",
                title: 'Please fill all the fields!!'
            });
            return;
        }

        var pattern = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i);
        if (!pattern.test(upmobile)) {
            Toast.fire({ icon: "error", title: 'Please Enter Number Only!' });
            return;
        } else if (upmobile.length !== 10) {
            Toast.fire({ icon: "error", title: 'Please enter valid  Mobile Number.' });
            return;
        }

        try {
            await updateDoc(doc(db, "restaurents", updocid), {
                restocat: uprestocat,
                restoname: uprestoname,
                address: upaddress,
                mobile: upmobile,
                email: upemail,
                tableshifting: uptableshifting,
            });

            Toast.fire({
                icon: "success",
                title: 'Restaurent Updated Successfully!!'
            });

            fetchData1();
        } catch (error) {
            console.log(error.message);
        }

    }

    const locadmore = () => {
        let extlimti = datalimit;
        let netlimit = parseFloat(datalimit) + 10;
        setdatalimit(netlimit);
        fetchData1();

        const q1 = query(collection(db, 'restaurents'), orderBy('restoname', 'asc'), limit(netlimit), startAfter(extlimti))
        onSnapshot(q1, async (querySnapshot1) => {
            const combinedData = [];
            for (const doc1 of querySnapshot1.docs) {

                const g3 = query(collection(db, 'restaurents'));
                const snapshot1 = await getAggregateFromServer(g3, {
                    count: count()
                });

                combinedData.push({
                    id: doc1.id,
                    data: doc1.data(),
                    count: snapshot1.data().count
                });
            }
            setrestaurents(combinedData);
        });
    }

    return (
        <div>
            <div className="content-wrapper">
                <br />
                <section className="content">
                    <div className="container-fluid">
                        <button className="btn btn-secondary " style={{ float: "right" }} >|| Restaurent Details ||</button>
                        <button className="btn btn-info btn-sm" data-toggle="modal" data-target="#insert-modal">Add Restaurent</button><br /><br />
                        <div className="card card-primary card-outline ex1">
                            <div className=" p-1">
                                &nbsp;&nbsp;<input type="search" value={searchText} onChange={(e) => setsearchText(e.target.value)}
                                    className='form-control col-sm-4' placeholder='Type here......' />&nbsp;
                            </div>
                            <div className="card-body table-responsive p-0" >
                                <table className="table table-bordered table-hover table-striped table-head-fixed">
                                    <thead>
                                        <tr>
                                            <th>Sr No</th>
                                            <th>Type</th>
                                            <th>Restaurent Name</th>
                                            <th>Mobile</th>
                                            <th>Address</th>
                                            <th>Email</th>
                                            <th>Table Shifting</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            restaurents.map((item, num = 1) =>
                                                <tr key={num + 1}>
                                                    <td width="80">{num + 1}</td>
                                                    <td>{item.data.restocat}</td>
                                                    <td>{item.data.restoname}</td>
                                                    <td>{item.data.mobile} </td>
                                                    <td>{item.data.address} </td>
                                                    <td>{item.data.email} </td>
                                                    <td>{item.data.tableshifting} </td>
                                                    <td>
                                                        <a onClick={() => handleEdit(item.id, item.data.restocat, item.data.restoname, item.data.mobile, item.data.address, item.data.email, item.data.tableshifting)} href='#0' data-toggle="modal" data-target="#text-modal1" className="btn btn-sm btn-success" > <i className="fa fa-edit"></i></a>&nbsp;
                                                        <a onClick={() => handleDelete(item.id)} className="btn btn-sm btn-danger" href='#0' > <i className="fa fa-trash"></i></a>
                                                    </td>
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                                {/* <button onClick={() => locadmore()}>Load More..</button> */}
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* ============================================= modal start ================================================= */}

            <div className="modal fade" id="insert-modal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Add Restaurent</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <label>Restaurent Category</label>
                                        <select value={restocat} onChange={(e) => setrestocat(e.target.value)} className="form-control" >
                                            <option value="Resto">Resto</option>
                                            <option value="Bar">Bar</option>
                                            <option value="Cafe">Cafe</option>
                                        </select>
                                    </div>
                                    <div className="col-sm-6">
                                        <label>  Restaurent Name</label>
                                        <input type="text" value={restoname} required onChange={(e) => setrestoname(e.target.value)} className="form-control" />
                                    </div>
                                    <div className="col-sm-6 mt-2">
                                        <label>  Mobile</label>
                                        <input type="text" value={mobile} minLength={10} maxLength={10} required pattern='/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i' onChange={(e) => setmobile(e.target.value)} className="form-control" />
                                    </div>
                                    <div className="col-sm-6 mt-2">
                                        <label> Address</label>
                                        <input type="text" value={address} required onChange={(e) => setaddress(e.target.value)} className="form-control" />
                                    </div>
                                    <div className="col-sm-6 mt-2">
                                        <label> Email</label>
                                        <input type="email" value={email} required onChange={(e) => setemail(e.target.value)} className="form-control" />
                                    </div>
                                    <div className="col-sm-6 mt-2">
                                        <label>Table Shifting</label>
                                        <select value={tableshifting} onChange={(e) => settableshifting(e.target.value)} className="form-control" >
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-between">
                                <button type="submit" className="btn btn-primary" >Save </button>
                                <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>


            <div className="modal fade" id="text-modal1">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Edit Restaurent</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <form onSubmit={handleUpdate}>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <label>Restaurent Category</label>
                                        <select value={uprestocat} required onChange={(e) => setuprestocat(e.target.value)} className="form-control" >
                                            <option value="Resto">Resto</option>
                                            <option value="Bar">Bar</option>
                                            <option value="Cafe">Cafe</option>
                                        </select>
                                    </div>
                                    <div className="col-sm-6">
                                        <label> Restaurent Name</label>
                                        <input type="text" value={uprestoname} required onChange={(e) => setuprestoname(e.target.value)} className="form-control" placeholder="Enter  Item Name ..." />
                                    </div>
                                    <div className="col-sm-6 mt-2">
                                        <label>  Mobile</label>
                                        <input type="text" value={upmobile} pattern='/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i' required onChange={(e) => setupmobile(e.target.value)} className="form-control" />
                                    </div>

                                    <div className="col-sm-6 mt-2">
                                        <label> Address</label>
                                        <input type="text" value={upaddress} required onChange={(e) => setupaddress(e.target.value)} className="form-control" />
                                    </div>
                                    <div className="col-sm-6 mt-2">
                                        <label> Email</label>
                                        <input type="email" value={upemail} required onChange={(e) => setupemail(e.target.value)} className="form-control" />
                                    </div>
                                    <div className="col-sm-6 mt-2">
                                        <label>Table Shifting</label>
                                        <select value={uptableshifting} onChange={(e) => setuptableshifting(e.target.value)} className="form-control" >
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-between">
                                <button type="submit" className="btn btn-primary">Update </button>
                                <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    )
}
