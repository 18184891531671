import React, { useEffect, useState, useRef } from 'react'
import Swal from 'sweetalert2';
import { collection, query, orderBy, onSnapshot, deleteDoc, doc, setDoc, datalimit, limit, getDoc } from 'firebase/firestore'
import { auth, db } from '../firebase';
import '../App.css'

let propid = localStorage.getItem('propid');
export default function Propertydetails() {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });

    const [userData, setUserData] = useState();

    const [updocid, setupdocid] = useState("");
    const [createdat, setcreatedat] = useState("");
    const [uprestocat, setuprestocat] = useState("");
    const [uprestoname, setuprestoname] = useState("");
    const [upaddress, setupaddress] = useState("");
    const [upmobile, setupmobile] = useState("");
    const [upemail, setupemail] = useState("");
    const [uptableshifting, setuptableshifting] = useState("");
    const [gstno, setgstno] = useState("");
    const [gstname, setgstname] = useState("");
    const [gstyesno, setgstyesno] = useState("");


    useEffect(() => {
        fetchData1()
    }, []);



    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            setUserData(user);
        });
    });

    const fetchData1 = async () => {
        try {

            const docRef1 = doc(db, "restaurents", propid);
            const docSnap1 = await getDoc(docRef1);
            setupdocid(docSnap1.id);
            setcreatedat(docSnap1.data().created);
            setuprestocat(docSnap1.data().restocat);
            setuprestoname(docSnap1.data().restoname);
            setupaddress(docSnap1.data().address);
            setupmobile(docSnap1.data().mobile);
            setupemail(docSnap1.data().email);
            setuptableshifting(docSnap1.data().tableshifting);
            setgstname(docSnap1.data().gstname);
            setgstno(docSnap1.data().gstno);
            setgstyesno(docSnap1.data().gstyesno);

        } catch (error) {
            Toast.fire({
                icon: "error",
                title: error.message
            });
        }
    }



    const handleUpdate = async () => {
        if (!uprestoname || !upmobile) {
            Toast.fire({
                icon: "error",
                title: 'Please fill all the fields!!'
            });
            return;
        }

        var pattern = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i);
        if (!pattern.test(upmobile)) {
            Toast.fire({ icon: "error", title: 'Please Enter Number Only!' });
            return;
        } else if (upmobile.length !== 10) {
            Toast.fire({ icon: "error", title: 'Please enter valid  Mobile Number.' });
            return;
        }

        try {
            await setDoc(doc(db, "restaurents", updocid), {
                restocat: uprestocat,
                restoname: uprestoname || null,
                address: upaddress || null,
                mobile: upmobile || null,
                email: upemail || null,
                tableshifting: uptableshifting || null,
                gstno: gstno || null,
                gstname: gstname || null,
                gstyesno: gstyesno || null,
                uid: userData.uid,
                created: createdat
            });

            Toast.fire({
                icon: "success",
                title: 'Restaurent Updated Successfully!!'
            });

            fetchData1();
        } catch (error) {
            console.log(error.message);
        }

    }


    return (
        <div>
            <div className="content-wrapper">
                <br />
                <section className="content">
                    <div className="container-fluid">
                        <button className="btn btn-secondary " style={{ float: "right" }} >|| Restaurent Details ||</button><br /><br />
                        <div className="card card-primary card-outline ">
                            <div className="card-body" >
                                <div className="row">
                                    <div className="col-sm-6">
                                        <label>Restaurent Category</label>
                                        <select value={uprestocat} onChange={(e) => setuprestocat(e.target.value)} className="form-control" >
                                            <option value="Resto">Resto</option>
                                            <option value="Bar">Bar</option>
                                            <option value="Cafe">Cafe</option>
                                        </select>
                                    </div>
                                    <div className="col-sm-6">
                                        <label> Restaurent Name</label>
                                        <input type="text" value={uprestoname} onChange={(e) => setuprestoname(e.target.value)} className="form-control" placeholder="Enter  Item Name ..." />
                                    </div>
                                    <div className="col-sm-6 mt-2">
                                        <label>  Mobile</label>
                                        <input type="text" value={upmobile} pattern='/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i' onChange={(e) => setupmobile(e.target.value)} className="form-control" />
                                    </div>

                                    <div className="col-sm-6 mt-2">
                                        <label> Address</label>
                                        <input type="text" value={upaddress} onChange={(e) => setupaddress(e.target.value)} className="form-control" />
                                    </div>
                                    <div className="col-sm-6 mt-2">
                                        <label> Email</label>
                                        <input type="email" value={upemail} onChange={(e) => setupemail(e.target.value)} className="form-control" />
                                    </div>
                                    <div className="col-sm-6 mt-2">
                                        <label>Table Shifting</label>
                                        <select value={uptableshifting} onChange={(e) => setuptableshifting(e.target.value)} className="form-control" >
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                    </div>
                                    <div className="col-sm-6 mt-2">
                                        <label> GST Name</label>
                                        <input type="text" value={gstname} onChange={(e) => setgstname(e.target.value)} className="form-control" />
                                    </div>
                                    <div className="col-sm-6 mt-2">
                                        <label> GST No</label>
                                        <input type="text" value={gstno} onChange={(e) => setgstno(e.target.value)} className="form-control" />
                                    </div>
                                    <div className="col-sm-6 mt-2">
                                        <label>GST Display</label>
                                        <select value={gstyesno} onChange={(e) => setgstyesno(e.target.value)} className="form-control" >
                                            <option></option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                    </div>
                                </div><br />
                                <center>
                                    <button onClick={() => handleUpdate()} className='btn btn-success'>Submit</button>
                                </center>
                            </div>
                        </div>
                    </div>
                </section>
            </div>



        </div>
    )
}
