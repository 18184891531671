import React, { useEffect, useState, useRef } from 'react'

function Admindashboard() {


    return (
        <div>
            <div className="content-wrapper">
                <section className="content"><br />
                    <button className="btn btn-secondary " style={{ float: "right" }} >|| Dashboard ||</button><br /><br />
                    <div className="container-fluid">
                        {/* Small boxes (Stat box) */}
                        <div className="row">
                            <div className="col-lg-3 col-6">
                                {/* small box */}
                                <div className="small-box bg-pink">
                                    <div className="inner">
                                        <h3>0</h3>
                                        <p> Total Restaurant</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fa fa-table" />
                                    </div>
                                </div>
                            </div>
                            {/* ./col */}
                            <div className="col-lg-3 col-6">
                                {/* small box */}
                                <div className="small-box bg-info">
                                    <div className="inner">
                                        <h3>0</h3>
                                        <p>Todays Restaurant</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fas fa-users" />
                                    </div>
                                </div>
                            </div>
                            {/* ./col */}
                            <div className="col-lg-3 col-6">
                                {/* small box */}
                                <div className="small-box bg-purple">
                                    <div className="inner">
                                        <h3>0</h3>
                                        <p>Active Restaurant</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fas fa-bars" />
                                    </div>
                                </div>
                            </div>
                            {/* ./col */}
                            <div className="col-lg-3 col-6">
                                {/* small box */}
                                <div className="small-box bg-teal">
                                    <div className="inner">
                                        <h3>0</h3>
                                        <p>Deactive Restaurant</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fas fa-address-card" />
                                    </div>
                                </div>
                            </div>
                            {/* ./col */}
                        </div>
                    </div>{/* /.container-fluid */}
                </section>

            </div>


        </div>
    )
}

export default Admindashboard
