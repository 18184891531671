import React, { useEffect, useState, useRef } from 'react'
import '.././App.css';
import Swal from 'sweetalert2';
import { auth, db } from '../firebase';
import { collection, addDoc, Timestamp, query, orderBy, onSnapshot, getDoc, getDocs, deleteDoc, doc, setDoc, where, updateDoc, limit } from 'firebase/firestore'
import moment from 'moment';

let propid = localStorage.getItem('propid');
export default function Foodorders() {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });
    const [userData, setUserData] = useState();
    const [orderitems, setorderitems] = useState([]);

    useEffect(() => {
        fetchData1()
    }, []);



    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            setUserData(user);
        });
    });

    const fetchData1 = async () => {
        try {

            const q3 = query(collection(db, 'orderitems'), where('propid', '==', propid), where('kot', '==', 'YES'), orderBy('created', 'desc'), limit(500))
            onSnapshot(q3, async (querySnapshot1) => {

                const combinedData1 = [];
                for (const doc of querySnapshot1.docs) {
                    console.log(doc.data().status);
                    if (doc.data().status === 'Pending') {
                        combinedData1.push({
                            id: doc.id,
                            data: doc.data(),
                            ordertime: doc.data().created.toDate().toString(),
                            completetime: "",
                            diff: doc.data().created.toDate().toString(),
                        });
                    } else {

                        const postTime = moment(doc.data().created.toDate().toString());
                        const now = moment(doc.data().status.toDate().toString());
                        const duration = moment.duration(now.diff(postTime));
                        let timdiff;
                        if (duration.asSeconds() < 60) {
                            timdiff = (`${Math.floor(duration.asSeconds())} sec`);
                        } else if (duration.asMinutes() < 60) {
                            timdiff = (`${Math.floor(duration.asMinutes())} min`);
                        } else if (duration.asHours() < 24) {
                            timdiff = (`${Math.floor(duration.asHours())} hours`);
                        } else if (duration.asDays() < 7) {
                            timdiff = (`${Math.floor(duration.asDays())} days`);
                        } else {
                            timdiff = (postTime.format('MMM DD, YYYY'));
                        }

                        console.log(timdiff);

                        combinedData1.push({
                            id: doc.id,
                            data: doc.data(),
                            ordertime: doc.data().created.toDate().toString(),
                            completetime: doc.data().status.toDate().toString(),
                            timdiff: timdiff,
                        });
                    }

                }
                setorderitems(combinedData1);
            });



        } catch (error) {
            Toast.fire({
                icon: "error",
                title: error.message
            });
        }
    }

    const orderComlete = async (docid, qty) => {
        await updateDoc(doc(db, 'orderitems', docid), {
            status: Timestamp.now(),
            complete: qty,
        });

        Toast.fire({
            icon: "success",
            title: 'Order  Successfully Completed!!'
        });

    }

    return (
        <div>
            <div className="content-wrapper">
                <br />
                <section className="content">
                    <div className="container-fluid">
                        <button className="btn btn-secondary " style={{ float: "right" }} >|| Food  Orders ||</button><br /><br />
                        <div className="card card-primary card-outline ">
                            <div className="card-body " >
                                <table className='table'>
                                    <tr>
                                        <th width="120">Item Name</th>
                                        <th width="30">Qty</th>
                                        <th width="30">Bill No</th>
                                        <th width="30">Order Date</th>
                                        <th width="30">Order Time</th>
                                        <th width="30">Completed Time</th>
                                        <th width="30">Food Making Time</th>
                                        <th width="50">Action</th>
                                    </tr>
                                    {
                                        orderitems.map((item, index) =>
                                            <tr key={index}>
                                                <td>{item.data.itemname}  </td>
                                                <td>{item.data.quantity}</td>
                                                <td>{item.data.billno}</td>

                                                <td>{moment(item.ordertime).format('DD/MM/YYYY')}</td>
                                                <td>{moment(item.ordertime).format('hh:mm:ss A')}</td>
                                                <td>
                                                    {

                                                        item.data.status !== "Pending" &&
                                                        <span>{moment(item.completetime).format('hh:mm:ss A')}</span>
                                                    }
                                                </td>
                                                <td>{item.timdiff}</td>
                                                <td>
                                                    {

                                                        item.data.status === "Pending" ?
                                                            <a style={{ width: "100%" }} onClick={() => { if (window.confirm('Are u sure to complete order?')) { orderComlete(item.id, item.data.quantity) }; }} className="btn btn-xs  btn-sm btn-info" href='#0'> <i className="fa fa-check"></i>  Food Ready</a> :
                                                            <button style={{ width: "100%" }} className='btn btn-xs btn-success'>Done</button>
                                                    }
                                                </td>

                                            </tr>
                                        )
                                    }
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </div >

        </div >
    )
}
