import React, { useEffect, useState, useRef } from 'react'
import '.././App.css';
import Swal from 'sweetalert2';
import { auth, db } from '../firebase';
import { collection, addDoc, Timestamp, query, orderBy, onSnapshot, getDoc, getDocs, deleteDoc, doc, setDoc, where, limit, getCountFromServer, updateDoc, writeBatch, getAggregateFromServer, sum } from 'firebase/firestore'
import Select from 'react-select'
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';

let propid = localStorage.getItem('propid');
let role = localStorage.getItem('role');
function Tableorders() {
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });
  const printRef = useRef();
  const printRef1 = useRef();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  })

  const handleKotPrint = useReactToPrint({
    content: () => printRef1.current,
  })

  const [loadincount, setloadincount] = useState(true);
  const [username, setusername] = useState("");

  const [billtype, setbilltype] = useState("Regular Bill");
  const [note, setnote] = useState("");


  const [bookings, setbookings] = useState([]);
  const [bookingData, setbookingData] = useState("");
  const [bookingId, setbookingId] = useState("");


  const [userData, setUserData] = useState();
  const [seatingCat, setseatingCat] = useState([]);
  const [seatings, setseatings] = useState([]);
  const [productCat, setproductCat] = useState([]);
  const [products, setproducts] = useState([]);
  const [orderitems, setorderitems] = useState([]);
  const [printorderitems, setprintorderitems] = useState([]);
  const [customers, setcustomers] = useState([]);
  const [staffs, setstaffs] = useState([]);

  const [name, setname] = useState("");
  const [mobile, setmobile] = useState("");
  const [address, setaddress] = useState("");

  const [orderDocid, setorderDocid] = useState("");
  const [nofopax, setnofopax] = useState("");
  const [tableid, settableid] = useState("");
  const [tablename, settablename] = useState("");
  const [orderdate, setorderdate] = useState("");
  const [custid, setcustid] = useState("");
  const [customer, setcustomer] = useState("");
  const [staffid, setstaffid] = useState("");
  const [staff, setstaff] = useState("");
  const [billno, setbillno] = useState("");
  const [tablebillno, settablebillno] = useState("");
  const [subtotal, setsubtotal] = useState(0);
  const [tax, settax] = useState(0);
  const [servicecharge, setservicecharge] = useState(0);
  const [totalamount, settotalamount] = useState(0);
  const [discount, setdiscount] = useState(0);
  const [paid, setpaid] = useState(0);
  const [remaining, setremaining] = useState("");
  const [paytype, setpaytype] = useState("");
  const [item, setitem] = useState("");

  const [revrem, setrevrem] = useState(0);

  const [removeNote, setremoveNote] = useState("");
  const [deleteItems, setdeleteItems] = useState([]);
  const [updateQtys, setupdateQtys] = useState([]);
  const [itemReady, setitemReady] = useState("Yes");
  const [newQty, setnewQty] = useState(0);

  const [openfoodname, setopenfoodname] = useState("");
  const [openfoodqty, setopenfoodqty] = useState(0);
  const [openfoodrate, setopenfoodrate] = useState(0);
  const [openfoodtotal, setopenfoodtotal] = useState(0);

  const [itemdesc, setitemdesc] = useState("");
  const [itemid, setitemid] = useState("");



  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setUserData(user);
      fetchUsersData()
    });
  });

  const fetchUsersData = async () => {
    try {
      if (userData) {
        if (loadincount) {
          const docRef = doc(db, "users", userData.uid)
          const docSnap = await getDoc(docRef)
          if (docSnap.exists()) {
            setusername(docSnap.data().name);
            setloadincount(false)
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }


  useEffect(() => {
    finaltotal()
    fetchData1();
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    setorderdate(formattedDate);
  }, []);


  const fetchData1 = async () => {
    try {
      const q = query(collection(db, 'seatingcats'), orderBy('srno', 'asc'))
      onSnapshot(q, (querySnapshot) => {
        setseatingCat(querySnapshot.docs.map(doc => ({
          id: doc.id,
          data: doc.data()
        })));
      });

      const fetchSeatings = async () => {
        const q1 = query(collection(db, 'seatings'), orderBy("created", "desc"));
        const querySnapshot1 = await getDocs(q1);
        const seatings = querySnapshot1.docs.map(doc => ({ id: doc.id, data: doc.data() }));
        return seatings;
      };

      const fetchOrders = async () => {
        const q2 = query(collection(db, 'orders'), where("status", "==", 'Booked'), orderBy("created", "asc"));
        const querySnapshot2 = await getDocs(q2);
        const orders = querySnapshot2.docs.map(doc => ({ id: doc.id, data: doc.data() }));
        return orders;
      };

      const combineSeatingsAndOrders = (seatings, orders) => {
        const combinedData = seatings.map(seating => {
          const seatingOrders = orders.filter(order => order.data.tableid === seating.id);
          return {
            id: seating.id,
            data: seating.data,
            status: seatingOrders.length > 0 ? "Booked" : "Available",
            orders: seatingOrders
          };
        });
        return combinedData;
      };

      const updateSeatings = async () => {
        try {
          const [seatings, orders] = await Promise.all([fetchSeatings(), fetchOrders()]);
          const combinedData = combineSeatingsAndOrders(seatings, orders);
          setseatings(combinedData);
        } catch (error) {
          console.error("Error fetching data: ", error);
        }
      };

      updateSeatings();

      const q2 = query(collection(db, 'productcats'))
      onSnapshot(q2, (querySnapshot) => {
        setproductCat(querySnapshot.docs.map(doc => ({
          id: doc.id,
          data: doc.data()
        })));
      });

      const q3 = query(collection(db, 'products'), orderBy("prodname", "asc"))
      onSnapshot(q3, async (querySnapshot1) => {
        const combinedData1 = [];
        let rohan = 0;
        for (const doc1 of querySnapshot1.docs) {
          rohan++;
          combinedData1.push({
            id: doc1.id,
            data: doc1.data(),
            unit: doc1.data().unitname,
            label: rohan + ". " + doc1.data().prodname,
            value: doc1.id,
          });
        }
        setproducts(combinedData1);
      });

      const q6 = query(collection(db, 'restocustomers'))
      onSnapshot(q6, (querySnapshot) => {
        setcustomers(querySnapshot.docs.map(doc => ({
          id: doc.id,
          data: doc.data(),
          label: doc.data().name,
          value: doc.id,
        })));
      });

      const q7 = query(collection(db, 'staffs'))
      onSnapshot(q7, (querySnapshot) => {
        setstaffs(querySnapshot.docs.map(doc => ({
          id: doc.id,
          data: doc.data(),
          label: doc.data().name,
          value: doc.id,
        })));
      });

      const q8 = query(collection(db, 'bookings'), orderBy("createdAt", "asc"), where('status', '==', 'Booked'))
      onSnapshot(q8, async (querySnapshot1) => {
        const combinedData1 = [];
        for (const doc1 of querySnapshot1.docs) {
          for (let i = 0; i < doc1.data().rooms.length; i++) {
            combinedData1.push({
              id: doc1.id,
              data: doc1.data(),
              label: doc1.data().rooms[i].roomname + " / " + doc1.data().customers.custname,
              value: doc1.id,
            });
          }
        }
        setbookings(combinedData1);
      });

    } catch (error) {
      Toast.fire({
        icon: "error",
        title: error.message
      });
    }
  }

  const fetchProducts = (prodcatid) => {
    const q3 = query(collection(db, 'products'), where("prodcatid", "==", prodcatid))
    onSnapshot(q3, async (querySnapshot1) => {
      const combinedData = [];
      for (const doc1 of querySnapshot1.docs) {
        combinedData.push({
          id: doc1.id,
          data: doc1.data(),
          unit: doc1.data().unitname,
          label: doc1.data().prodname + " " + doc1.data().unitname,
          value: doc1.id,
        });
      }
      setproducts(combinedData);
    });
  }

  const handleSelectItem = (item) => {
    setitem("");
    addOrderItems(item, item.id, item.data.rate)
  };

  const handleSelectItem1 = async (item) => {
    setcustomer(item);
    setcustid(item.id);
    setbookingId(item.data.bookingId);
    if (orderDocid) {
      await updateDoc(doc(db, 'orders', orderDocid), {
        custid: item.id || null,
        customer: item || null,
      });
    }

    const q1 = query(collection(db, 'customeracchistory'), where("custid", "==", item.id));
    const snapshot = await getAggregateFromServer(q1, {
      paid: sum('paid')
    });

    const q2 = query(collection(db, 'orders'), where("custid", "==", item.id));
    const snapshot1 = await getAggregateFromServer(q2, {
      subtotal: sum('subtotal')
    });

    const g3 = query(collection(db, 'customeracchistory'), where("custid", "==", item.id));
    const snapshot2 = await getAggregateFromServer(g3, {
      discount: sum('discount')
    });

    let remaining = parseFloat(snapshot1.data().subtotal) - parseFloat(snapshot2.data().discount) - parseFloat(snapshot.data().paid);
    setrevrem(remaining);

  };

  const handleSelectItem2 = async (item) => {
    setstaff(item);
    setstaffid(item.id);
  }

  const addTableId = async (tableid, tablename) => {
    setrevrem(0);
    setsubtotal(0);
    setservicecharge(0)
    settax(0);
    setnofopax(0);
    settotalamount(0);
    settableid(tableid); settablename(tablename);
    setorderDocid("");
    setorderitems([]);
    const coll = collection(db, "orders");
    const snapshot = await getCountFromServer(coll, where("orderdate", "==", orderdate));
    let billno = parseFloat(snapshot.data().count) + 1;
    setbillno(billno);

    setcustid(snapshot.data().custid);
    setcustomer("");
    setstaff("");
    setstaffid(snapshot.data().staffid);

    const coll1 = collection(db, "orders");
    const snapshot1 = await getCountFromServer(coll1, where("tableid", "==", tableid), where("orderdate", "==", orderdate));
    let tablebillno = parseFloat(snapshot1.data().count) + 1;
    settablebillno(tablebillno);
  }

  const addTableId1 = async (tableid, tablename, tableno, billno1, orderid, custid, customer, staffid, staff, nofopax) => {
    fetchOrderItems(tableid, tableno, orderid);
    settablebillno(tableno);
    settableid(tableid);
    settablename(tablename);
    setorderDocid(orderid);
    setbillno(billno1);
    setrevrem(0);
    setsubtotal(0);
    settax(0);
    setservicecharge(0)
    settotalamount(0);
    setnofopax(nofopax)

    if (custid) {
      setcustid(custid);
      setcustomer(customer);
      setbookingId(customer.data.bookingId);

      setstaff(staff);
      setstaffid(staffid);

      const q1 = query(collection(db, 'customeracchistory'), where("custid", "==", custid));
      const snapshot = await getAggregateFromServer(q1, {
        paid: sum('paid')
      });

      const q2 = query(collection(db, 'orders'), where("custid", "==", custid));
      const snapshot1 = await getAggregateFromServer(q2, {
        subtotal: sum('subtotal')
      });

      const g3 = query(collection(db, 'customeracchistory'), where("custid", "==", custid));
      const snapshot2 = await getAggregateFromServer(g3, {
        discount: sum('discount')
      });

      let remaining = parseFloat(snapshot1.data().subtotal) - parseFloat(snapshot2.data().discount) - parseFloat(snapshot.data().paid);
      setrevrem(remaining);

    }

  }

  const addOrderItems = async (item, itemid, rate) => {
    try {
      if (!orderDocid) {
        const docRef = await addDoc(collection(db, 'orders'), {
          propid: propid || null,
          bookingId: bookingId || null,
          tableid: tableid || null,
          nofopax: nofopax || null,
          custid: custid || null,
          staffid: staffid || null,
          customer: customer || null,
          staff: staff || null,
          orderdate: orderdate || null,
          tablebillno: tablebillno || null,
          billno: billno || null,
          subtotal: subtotal || null,
          tax: tax || null,
          servicecharge: servicecharge || null,
          totalamount: totalamount || null,
          discount: discount || null,
          paid: paid || null,
          remaining: remaining || null,
          paytype: paytype || null,
          billtype: billtype || null,
          note: note || null,
          status: 'Booked',
          uid: userData.uid,
          created: Timestamp.now()
        });

        let taxamount = parseFloat(rate) * 5 / 100;
        let taxadded = parseFloat(taxamount) + parseFloat(rate)

        await addDoc(collection(db, 'orderitems'), {
          propid: propid || null,
          bookingId: bookingId || null,
          orderid: docRef.id,
          tableid: tableid || null,
          nofopax: nofopax || null,
          custid: custid || null,
          staffid: staffid || null,
          customer: customer || null,
          staff: staff || null,
          orderdate: orderdate || null,
          tablebillno: tablebillno || null,
          billno: billno || null,
          itemname: item.data.prodname || null,
          itemdesc: itemdesc,
          kot: item.data.kot || null,
          status: 'Pending',
          complete: 0,
          itemid: itemid || null,
          unitname: item.unit || null,
          quantity: 1 || null,
          rate: rate || null,
          total: parseFloat(rate),
          taxamount: taxamount || null,
          taxaddedamount: taxadded || null,
          uid: userData.uid,
          created: Timestamp.now()
        });

        // =============== Product Stock Update ================
        const docProdRef = doc(db, 'products', itemid);
        const docSnap = await getDoc(docProdRef);
        if (docSnap.exists()) {
          let newstock = parseFloat(docSnap.data().stock) - 1;
          await updateDoc(doc(db, 'products', itemid), {
            stock: newstock,
          });
        }
        // =============== Product Stock Update ================
        fetchOrderItems(tableid, tablebillno, docRef.id);
        setorderDocid(docRef.id);
      } else {
        await setDoc(doc(db, 'orders', orderDocid), {
          propid: propid || null,
          tableid: tableid || null,
          nofopax: nofopax || null,
          bookingId: bookingId || null,
          custid: custid || null,
          staffid: staffid || null,
          customer: customer || null,
          staff: staff || null,
          orderdate: orderdate || null,
          tablebillno: tablebillno || null,
          billno: billno || null,
          subtotal: subtotal || null,
          tax: tax || null,
          servicecharge: servicecharge || null,
          totalamount: totalamount || null,
          discount: discount || null,
          paid: paid || null,
          remaining: remaining || null,
          paytype: paytype || null,
          billtype: billtype || null,
          note: note || null,
          status: 'Booked',
          uid: userData.uid,
          created: Timestamp.now()
        });

        const coll1 = query(collection(db, "orderitems"), where("itemid", "==", itemid), where("orderid", "==", orderDocid));
        const snapshot1 = await getDocs(coll1);
        if (snapshot1.docs.length === 0) {

          let taxamount = parseFloat(rate) * 5 / 100;
          let taxadded = parseFloat(taxamount) + parseFloat(rate)

          await addDoc(collection(db, 'orderitems'), {
            orderid: orderDocid,
            propid: propid || null,
            tableid: tableid || null,
            nofopax: nofopax || null,
            bookingId: bookingId || null,
            custid: custid || null,
            staffid: staffid || null,
            customer: customer || null,
            staff: staff || null,
            orderdate: orderdate || null,
            itemdesc: itemdesc,
            tablebillno: tablebillno || null,
            billno: billno || null,
            itemname: item.data.prodname || null,
            kot: item.data.kot || null,
            status: 'Pending',
            complete: 0,
            itemid: itemid || null,
            unitname: item.unit || null,
            quantity: 1 || null,
            rate: rate || null,
            total: parseFloat(rate),
            taxamount: taxamount || null,
            taxaddedamount: taxadded || null,
            uid: userData.uid,
            created: Timestamp.now()
          });
        } else {
          let qty = parseFloat(snapshot1.docs[0].data().quantity) + 1;
          let tot = parseFloat(qty) * parseFloat(rate);
          let taxamount = parseFloat(tot) * 5 / 100;
          let taxadded = parseFloat(taxamount) + parseFloat(rate)

          const updateData = {
            orderid: orderDocid || null,
            propid: propid || null,
            tableid: tableid || null,
            nofopax: nofopax || null,
            custid: custid || null,
            staffid: staffid || null,
            bookingId: bookingId || null,
            customer: customer || null,
            itemdesc: itemdesc,
            staff: staff || null,
            orderdate: orderdate || null,
            tablebillno: tablebillno || null,
            billno: billno || null,
            itemname: item.data.prodname || null,
            kot: item.data.kot || null,
            status: 'Pending',
            complete: 0,
            itemid: itemid || null,
            quantity: qty || null,
            rate: rate || null,
            total: parseFloat(tot),
            taxamount: taxamount || null,
            taxaddedamount: taxadded || null,
            uid: userData.uid,
            created: Timestamp.now()
          };
          const q = query(collection(db, "orderitems"), where("itemid", "==", itemid), where("orderid", "==", orderDocid));
          const querySnapshot = await getDocs(q);
          const batch = writeBatch(db);
          querySnapshot.forEach((doc) => {
            batch.update(doc.ref, updateData);
          });
          await batch.commit();
        }

        // =============== Product Stock Update ================
        const docProdRef = doc(db, 'products', itemid);
        const docSnap = await getDoc(docProdRef);
        if (docSnap.exists()) {
          let newstock = parseFloat(docSnap.data().stock) - 1;
          await updateDoc(doc(db, 'products', itemid), {
            stock: newstock,
          });
        }
        // =============== Product Stock Update ================

        fetchOrderItems(tableid, tablebillno, orderDocid);
      }
      fetchData1();

    } catch (error) {
      console.log(error.message);
    }

  }

  const fetchOrderItems = async (tableid, tablebillno, orderid) => {
    const q = query(collection(db, "orderitems"), where("tableid", "==", tableid), where("tablebillno", "==", tablebillno),
      where("orderid", "==", orderid));
    onSnapshot(q, (querySnapshot) => {
      setorderitems(querySnapshot.docs.map(doc => ({
        id: doc.id,
        data: doc.data()
      })));
    });

    const q3 = query(collection(db, "orderitems"), where("tableid", "==", tableid), where("tablebillno", "==", tablebillno),
      where("orderid", "==", orderid));
    onSnapshot(q3, async (querySnapshot1) => {
      const combinedData = [];
      for (const doc1 of querySnapshot1.docs) {
        if (doc1.data().kot === 'YES') {
          let qty = parseFloat(doc1.data().quantity) - parseFloat(doc1.data().complete);
          combinedData.push({
            id: doc1.id,
            data: doc1.data(),
            qty: qty
          });
        }
      }
      setprintorderitems(combinedData);
    });

    const q1 = query(collection(db, 'orderitems'), where("tableid", "==", tableid), where("tablebillno", "==", tablebillno),
      where("orderid", "==", orderid));

    const querySnapshot = await getDocs(q1);
    let sum = 0;
    let tax = 0;
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      sum += data.total ? data.total : 0;
      tax += data.taxamount ? data.taxamount : 0;
    });

    settax(tax.toFixed(2));
    setsubtotal(sum.toFixed(2));
    let totalam = parseFloat(sum) + parseFloat(tax) + parseFloat(servicecharge);
    settotalamount(totalam.toFixed(2));
    finaltotal()
  }

  const deleteOrderItem = async (docid, itemid, quantity, tableid, tablebillno, orderid) => {
    setdeleteItems([docid, itemid, quantity, tableid, tablebillno, orderid])
  }

  const upOrderQty = async (type, docid, itemid, qty, tableid, tablebillno, orderid, rate) => {
    setupdateQtys([type, docid, itemid, qty, tableid, tablebillno, orderid, rate])
  }


  const finaltotal = () => {
    // let totall =  parseFloat(subtotal) + parseFloat(tax) + parseFloat(servicecharge)
    // settotalamount(totall.toFixed(2));
    // console.log(4512451248512);

    let remainingnew = (parseFloat(totalamount)) - (parseFloat(paid) + parseFloat(discount));
    if (remainingnew < 0) {
      // Toast.fire({ icon: "error", title: 'Remaining should not be less than 0' });
      setpaid(0);
      setremaining(totalamount);
      return;
    }
    if (paid === '') {
      setremaining(totalamount);
    } else {
      setremaining(remainingnew);
    }
  }

  const handleSubmit = async () => {
    await addDoc(collection(db, 'restocustomers'), {
      bookingId: bookingId,
      name: name,
      mobile: mobile,
      address: address,
      uid: userData.uid,
      propid: propid,
      total: 0,
      paid: 0,
      email: "",
      remaining: 0,
      created: Timestamp.now()
    });
    setname(""); setmobile(""); setaddress("");
    fetchData1()
    Toast.fire({
      icon: "success",
      title: 'Customer Added Successfully!!'
    });
  }

  const saveBill = async () => {
    let rpaid = parseFloat(paid);
    if (subtotal === "" || rpaid === "" || remaining === "") {
      Toast.fire({ icon: "error", title: 'Please check the  total amount , paid & Remaining value is correct' });
      return;
    }
    try {
      // --------------------- update status of purchase table --------------------
      await updateDoc(doc(db, 'orders', orderDocid), {
        status: 'Paid',
        bookingId: bookingId || null,
        custid: custid || null,
        propid: propid || null,
        staffid: staffid || null,
        customer: customer || null,
        staff: staff || null,
        billno: billno || null,
        paiddate: orderdate || null,
        subtotal: subtotal || null,
        tax: tax || null,
        servicecharge: servicecharge || null,
        totalamount: totalamount || null,
        discount: parseFloat(discount),
        paid: parseFloat(paid),
        remaining: remaining,
        paytype: paytype,
        billtype: billtype || null,
        note: note || null,
      });

      // --------------------- Add dealer account paid amount --------------------
      await addDoc(collection(db, 'customeracchistory'), {
        propid: propid || null,
        custid: custid || null,
        staffid: staffid || null,
        bookingId: bookingId || null,
        customer: customer || null,
        staff: staff || null,
        orderDocid: orderDocid || null,
        billno: billno || null,
        paiddate: orderdate || null,
        subtotal: subtotal || null,
        discount: parseFloat(discount),
        paid: parseFloat(paid),
        remaining: remaining,
        paytype: paytype,
        status: 'Active',
        note: 'Paid at the time bill',
        uid: userData.uid,
        created: Timestamp.now()
      });

      if (custid) {
        const q1 = query(collection(db, 'customeracchistory'), where("custid", "==", custid));
        const snapshot = await getAggregateFromServer(q1, {
          paidamount: sum('paid')
        });

        const q2 = query(collection(db, 'orders'), where("custid", "==", custid));
        const snapshot1 = await getAggregateFromServer(q2, {
          grandtotal: sum('subtotal')
        });

        const q3 = query(collection(db, 'orders'), where("custid", "==", custid));
        const snapshot2 = await getAggregateFromServer(q3, {
          discount: sum('discount')
        });

        await updateDoc(doc(db, "restocustomers", custid), {
          total: snapshot1.data().grandtotal,
          paid: snapshot.data().paidamount,
          remaining: parseFloat(snapshot1.data().grandtotal) - parseFloat(snapshot.data().paidamount) - parseFloat(snapshot2.data().discount),
        });

        const docRef1 = doc(db, "restocustomers", custid);
        const docSnap1 = await getDoc(docRef1);

        if (docSnap1.data().bookingId) {
          const docRef = doc(db, "bookings", bookingId);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            setbookingData(docSnap.data());

            let totalresto = parseFloat(totalamount) + parseFloat(docSnap.data().restoamount);
            let totaltot = parseFloat(totalamount) + parseFloat(docSnap.data().totalamount);
            let totalpaid = parseFloat(paid) + parseFloat(docSnap.data().advance);
            let totalrem = parseFloat(totaltot) - parseFloat(totalpaid);

            await updateDoc(doc(db, "bookings", bookingId), {
              restoamount: parseFloat(totalresto),
              totalamount: parseFloat(totaltot),
              advance: parseFloat(totalpaid),
              remaining: parseFloat(totalrem),
            });

          }
        }
      }

      setsubtotal(0); setpaid(0); setremaining(0); setorderDocid(""); setcustid(""); setbillno("");
      setpaytype("Cash"); setorderitems([]); setbookingId(""); settotalamount(0)
      fetchData1();

      Toast.fire({ icon: "success", title: 'Order Successfully Paid' });

      window.location.href = "/table-orders";

    } catch (error) {
      Toast.fire({ icon: "error", title: 'Something was wrong please try again' });
      console.log(error.message)
    }
  }


  const handleSelectItem3 = async (item) => {
    setbookingData(item);
    setbookingId(item.id);
    setname(item.data.customers.custname)
    setmobile(item.data.customers.mobile)
    setaddress(item.data.customers.city)
  };

  const handleRemoveItem = async () => {
    if (!removeNote) {
      Toast.fire({ icon: "error", title: 'Please enter note' });
      return
    }

    let itemname = "";
    if (deleteItems[1]) {
      const docProdRef = doc(db, 'products', deleteItems[1]);
      const docSnap = await getDoc(docProdRef);
      itemname = docSnap.data().prodname;
    }
    else {
      itemname = "";
    }

    const docProdRef1 = doc(db, 'seatings', deleteItems[3]);
    const docSnap1 = await getDoc(docProdRef1);

    await addDoc(collection(db, 'rejectionnote'), {
      removeNote: removeNote || null,
      docid: deleteItems[0],
      itemid: deleteItems[1],
      quantity: deleteItems[2],
      tableid: deleteItems[3],
      tablebillno: deleteItems[4],
      orderid: deleteItems[5],
      itemname: itemname,
      tableno: docSnap1.data().seatname,
      uid: userData.uid,
      created: Timestamp.now()
    });

    const taskDocRef = doc(db, 'orderitems', deleteItems[0])
    await deleteDoc(taskDocRef);
    const q1 = query(collection(db, 'orderitems'), where("orderid", "==", deleteItems[5]));
    onSnapshot(q1, async (querySnapshot) => {
      if (querySnapshot.size === 0) {
        const taskDoc2Ref = doc(db, 'orders', deleteItems[5])
        await deleteDoc(taskDoc2Ref);
        setorderDocid("")
      }
    });


    fetchData1()
    fetchOrderItems(deleteItems[3], deleteItems[4], deleteItems[5]);
    Toast.fire({ icon: "success", title: 'Order Items Sucessfully Removed' });

  }

  const handleUpdateItem = async () => {

    if (updateQtys[0] === 'Remove') {
      console.log(parseFloat(newQty));
      console.log(parseFloat(updateQtys[3]));

      if (parseFloat(newQty) > parseFloat(updateQtys[3])) {
        Toast.fire({ icon: "error", title: 'Item Quantity should not be greater than ' + updateQtys[3] });
        return
      }
      if (!removeNote) {
        Toast.fire({ icon: "error", title: 'Please enter note' });
        return
      }

    }
    if (updateQtys[0] === "Remove") {

      const docProdRef1 = doc(db, 'orderitems', updateQtys[1]);
      const docSnap1 = await getDoc(docProdRef1);
      if (docSnap1.exists()) {
        let newstock = parseFloat(docSnap1.data().quantity) - parseFloat(newQty);
        if (newstock < 1) {
          const taskDocRef = doc(db, 'orderitems', updateQtys[1])
          await deleteDoc(taskDocRef);
          const taskDoc2Ref = doc(db, 'orders', updateQtys[6])
          await deleteDoc(taskDoc2Ref);
          setorderDocid("")
        } else {
          await updateDoc(doc(db, 'orderitems', updateQtys[1]), {
            custid: custid,
            customer: customer,
            quantity: newstock,
            propid: removeNote,
            total: parseFloat(newstock) * parseFloat(updateQtys[7]),
          });
        }
      }

      fetchData1()
      fetchOrderItems(tableid, tablebillno, updateQtys[6]);
    }
    else {
      const docProdRef1 = doc(db, 'orderitems', updateQtys[1]);
      const docSnap1 = await getDoc(docProdRef1);
      if (docSnap1.exists()) {
        let newstock = parseFloat(docSnap1.data().quantity) + parseFloat(newQty);
        await updateDoc(doc(db, 'orderitems', updateQtys[1]), {
          quantity: newstock,
          total: parseFloat(newstock) * parseFloat(updateQtys[7]),
        });
      } else {
        const taskDocRef = doc(db, 'orderitems', updateQtys[1])
        await deleteDoc(taskDocRef);
      }


      fetchData1()
      fetchOrderItems(tableid, tablebillno, updateQtys[6]);
    }

    if (itemReady === 'Yes') {
      await updateDoc(doc(db, 'orderitems', updateQtys[1]), {
        complete: parseFloat(updateQtys[3]),
      });
    }

    setupdateQtys([]);
    setitemReady("Yes");
    setnewQty(0);
    fetchData1()

    Toast.fire({ icon: "success", title: 'Order Items Sucessfully Updated' });
  }


  const calOpenFood = () => {
    let total = parseFloat(openfoodqty) * parseFloat(openfoodrate)
    setopenfoodtotal(total)
  }

  const handleServiceCharge = (e) => {
    setservicecharge(e.target.value);
    if (e.target.value) {
      let total = parseFloat(subtotal) + parseFloat(tax) + parseFloat(e.target.value);
      settotalamount(total);
      setremaining(parseFloat(total) - parseFloat(paid) - parseFloat(discount))
    }
  }

  const handleDiscount = (e) => {
    setdiscount(e.target.value);
    if (e.target.value) {
      let total = parseFloat(totalamount) - parseFloat(e.target.value);
      setremaining(parseFloat(total) - parseFloat(paid))
    }
  }

  const handlePaid = (e) => {
    setpaid(e.target.value);
    if (e.target.value) {
      let total = parseFloat(totalamount) - parseFloat(discount) - parseFloat(e.target.value);
      setremaining(parseFloat(total))
    }
  }

  const addOpenFood = async () => {
    if (!openfoodname || !openfoodqty || !openfoodrate || !openfoodtotal) {
      Toast.fire({ icon: "error", title: 'Please fill all the fields' });
      return;
    }

    try {
      if (!orderDocid) {
        const docRef = await addDoc(collection(db, 'orders'), {
          propid: propid || null,
          bookingId: bookingId || null,
          tableid: tableid || null,
          nofopax: nofopax || null,
          custid: custid || null,
          staffid: staffid || null,
          customer: customer || null,
          staff: staff || null,
          orderdate: orderdate || null,
          tablebillno: tablebillno || null,
          billno: billno || null,
          subtotal: subtotal || null,
          tax: tax || null,
          servicecharge: servicecharge || null,
          totalamount: totalamount || null,
          discount: discount || null,
          paid: paid || null,
          remaining: remaining || null,
          paytype: paytype || null,
          billtype: billtype || null,
          note: note || null,
          status: 'Booked',
          uid: userData.uid,
          created: Timestamp.now()
        });


        await addDoc(collection(db, 'orderitems'), {
          propid: propid || null,
          bookingId: bookingId || null,
          orderid: docRef.id,
          tableid: tableid || null,
          nofopax: nofopax || null,
          custid: custid || null,
          staffid: staffid || null,
          customer: customer || null,
          staff: staff || null,
          orderdate: orderdate || null,
          tablebillno: tablebillno || null,
          billno: billno || null,
          itemname: openfoodname || null,
          itemdesc: itemdesc,
          kot: null,
          status: 'Pending',
          complete: 0,
          itemid: null,
          unitname: null,
          quantity: openfoodqty || null,
          rate: openfoodrate || null,
          total: parseFloat(openfoodtotal),
          taxamount: 0 || null,
          taxaddedamount: parseFloat(openfoodtotal) || null,
          uid: userData.uid,
          created: Timestamp.now()
        });

        // =============== Product Stock Update ================
        fetchOrderItems(tableid, tablebillno, docRef.id);
        setorderDocid(docRef.id);
      } else {
        await setDoc(doc(db, 'orders', orderDocid), {
          propid: propid || null,
          tableid: tableid || null,
          nofopax: nofopax || null,
          bookingId: bookingId || null,
          custid: custid || null,
          staffid: staffid || null,
          customer: customer || null,
          staff: staff || null,
          orderdate: orderdate || null,
          tablebillno: tablebillno || null,
          billno: billno || null,
          subtotal: subtotal || null,
          tax: tax || null,
          servicecharge: servicecharge || null,
          totalamount: totalamount || null,
          discount: discount || null,
          paid: paid || null,
          remaining: remaining || null,
          paytype: paytype || null,
          billtype: billtype || null,
          note: note || null,
          status: 'Booked',
          uid: userData.uid,
          created: Timestamp.now()
        });

        const coll1 = query(collection(db, "orderitems"), where("itemname", "==", openfoodname), where("orderid", "==", orderDocid));
        const snapshot1 = await getDocs(coll1);
        if (snapshot1.docs.length === 0) {

          await addDoc(collection(db, 'orderitems'), {
            orderid: orderDocid,
            propid: propid || null,
            tableid: tableid || null,
            nofopax: nofopax || null,
            bookingId: bookingId || null,
            custid: custid || null,
            staffid: staffid || null,
            customer: customer || null,
            staff: staff || null,
            orderdate: orderdate || null,
            itemdesc: itemdesc,
            tablebillno: tablebillno || null,
            billno: billno || null,
            itemname: openfoodname || null,
            kot: null,
            status: 'Pending',
            complete: 0,
            itemid: null,
            unitname: null,
            quantity: openfoodqty || null,
            rate: openfoodrate || null,
            total: parseFloat(openfoodtotal),
            taxamount: 0 || null,
            taxaddedamount: openfoodtotal || null,
            uid: userData.uid,
            created: Timestamp.now()
          });
        } else {
          let qty = parseFloat(snapshot1.docs[0].data().quantity) + 1;
          let tot = parseFloat(qty) * parseFloat(openfoodrate);
          let taxamount = 0;
          let taxadded = parseFloat(taxamount) + parseFloat(openfoodrate)

          const updateData = {
            orderid: orderDocid || null,
            propid: propid || null,
            tableid: tableid || null,
            nofopax: nofopax || null,
            custid: custid || null,
            staffid: staffid || null,
            bookingId: bookingId || null,
            customer: customer || null,
            itemdesc: itemdesc,
            staff: staff || null,
            orderdate: orderdate || null,
            tablebillno: tablebillno || null,
            billno: billno || null,
            itemname: openfoodname || null,
            kot: null,
            status: 'Pending',
            complete: 0,
            itemid: null,
            quantity: qty || null,
            rate: openfoodrate || null,
            total: parseFloat(tot),
            taxamount: taxamount || null,
            taxaddedamount: taxadded || null,
            uid: userData.uid,
            created: Timestamp.now()
          };
          const q = query(collection(db, "orderitems"), where("itemname", "==", openfoodname), where("orderid", "==", orderDocid));
          const querySnapshot = await getDocs(q);
          const batch = writeBatch(db);
          querySnapshot.forEach((doc) => {
            batch.update(doc.ref, updateData);
          });
          await batch.commit();
        }

        setopenfoodname("");
        setopenfoodqty(0)
        setopenfoodrate(0)
        setopenfoodtotal(0)
        fetchOrderItems(tableid, tablebillno, orderDocid);
      }
      fetchData1();

    } catch (error) {
      console.log(error.message);
    }
  }

  const addRemark = async () => {
    await updateDoc(doc(db, 'orderitems', itemid), {
      itemdesc: itemdesc,
    });

    Toast.fire({ icon: "success", title: 'Item Remark Successfully Added' });
    setitemdesc("");
    fetchData1()

  }

  return (
    <div>
      <div className="content-wrapper">
        <section className="content">
          <div className="container-fluid">
            <button className="btn btn-secondary " style={{ float: "right", marginTop: 4 }} >|| Table Orders  ||</button><br /><br />
            <div className="card card-primary card-outline mt-1">
              <div className="card-body table-responsive p-3" >
                {
                  seatingCat.map((item1, index) =>
                    <div key={index} >
                      <h6 style={{ textTransform: "uppercase", marginLeft: 10 }}><b>{item1.data.catname}</b></h6>
                      <div className='row'>
                        {
                          seatings.map((item, index) =>
                            <>
                              {
                                item1.id === item.data.seatcatid ?
                                  <div key={index} className='col-sm-2'>
                                    {
                                      item.status === 'Booked' ?
                                        <>
                                          {
                                            item.orders.map((item1, index) =>
                                              <button key={index} className='tableboxred'
                                                onClick={() => addTableId1(item.id, item.data.seatname, item1.data.tablebillno,
                                                  item1.data.billno, item1.id, item1.data.custid, item1.data.customer, item1.data.staffid, item1.data.staff, item1.data.nofopax)} data-toggle="modal" data-target="#addorder-modal">
                                                <h6 className='tablename'><b>{item.data.seatname}</b></h6>
                                              </button>
                                            )
                                          }

                                        </>
                                        :
                                        <button key={index} className='tableboxgreen' onClick={() => addTableId(item.id, item.data.seatname)} data-toggle="modal" data-target="#addorder-modal">
                                          <h6 className='tablename'><b>{item.data.seatname}</b></h6>
                                        </button>
                                    }
                                  </div> : null
                              }
                            </>
                          )
                        }

                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </section>
      </div >

      <div className="modal fade" id="addorder-modal">
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content">
            <div className="modal-header" style={{ backgroundColor: "orangered", padding: 10 }}>
              <h5 className="modal-title" style={{ color: "white" }}>{tablename} Table</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span></button>
            </div>
            <div className="modal-body p-1">
              <div className='row'>
                <div className='col-sm-2'>
                  <div className='card' style={{ height: "100%", backgroundColor: "#F9F7F7" }}>
                    <div className='card-body'>
                      {
                        productCat.map((item, index) =>
                          <button key={index} onClick={() => fetchProducts(item.id)} style={{ textTransform: "uppercase", textAlign: "left", fontSize: 14 }} className='form-control btn btn-info mt-1'>{item.data.catname}</button>
                        )
                      }
                    </div>
                  </div>
                </div>
                <div className='col-sm-6'>
                  <div className='card' style={{ height: "100%", backgroundColor: "#F9F7F7" }}>
                    <div className='card-body'>
                      <div className='row'>
                        <div className='col-sm-5'>
                          <Select options={customers} value={customer} onChange={handleSelectItem1} />
                          <label>Outstanding Amount : <span style={{ color: "red" }}> Rs.{revrem}/- </span></label>
                        </div>
                        <div className='col-sm-5'>
                          <Select options={staffs} value={staff} onChange={handleSelectItem2} />
                        </div>
                        <div className='col-sm-2'>
                          <button className="btn btn-info btn-xs" style={{ float: "right" }} data-toggle="modal" data-target="#customer-modal">Add  Customer</button><br />
                        </div>
                      </div><hr />
                      <div className='row'>
                        <div className='col-sm-3'>
                          <label>No Of Person</label>
                          <input type="text" className='form-control' value={nofopax} onChange={(e) => setnofopax(e.target.value)} />
                        </div>
                        <div className='col-sm-9'>
                          <label>Search Product</label>
                          <Select options={products} value={item} onChange={handleSelectItem} /><hr />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-3">
                          <label> Open Food Name</label>
                          <input type="text" value={openfoodname} onChange={(e) => setopenfoodname(e.target.value)} className="form-control" />
                        </div>
                        <div className="col-sm-2">
                          <label>Rate</label>
                          <input type="text" value={openfoodrate} onChange={(e) => setopenfoodrate(e.target.value)} className="form-control" onKeyUp={calOpenFood} />
                        </div>
                        <div className="col-sm-2">
                          <label>Quantity</label>
                          <input type="number" value={openfoodqty} onChange={(e) => setopenfoodqty(e.target.value)} className="form-control" onKeyUp={calOpenFood} />
                        </div>
                        <div className="col-sm-3">
                          <label>Total</label>
                          <input type="text" value={openfoodtotal} onChange={(e) => setopenfoodtotal(e.target.value)} className="form-control" onKeyUp={calOpenFood} />
                        </div>
                        <div className="col-sm-2">
                          <br />
                          <button className='btn btn-success btn-sm form-control mt-2' onClick={addOpenFood}>Add </button>
                        </div>
                      </div><br />
                      <div className='row'>
                        {
                          products.map((item, index) =>
                            <div key={index} className='col-sm-3'>
                              <button onClick={() => addOrderItems(item, item.id, item.data.rate)} style={{ fontSize: 11, padding: 0 }} className='form-control btn btn-secondary mt-1'> {item.data.prodname} {item.unit}</button>
                            </div>
                          )
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-sm-4'>
                  <div className='card' style={{ height: "100%" }}>
                    <div className='card-body'>
                      <div className='row'>
                        <div className='col-sm-6'>
                          <label>Bill No : {billno}</label>
                        </div>
                        <div className='col-sm-6'>
                          <label style={{ float: "right" }}>Date No : {moment(orderdate).format('DD/MM/YYYY')}</label>
                        </div>
                      </div>
                      <div className='productBox '>
                        <table className='customers3'>
                          {
                            orderitems.map((item, index) =>
                              <tr key={index}>
                                <td>
                                  <a href='#0' data-toggle="modal" data-target="#itemdesc-modal" onClick={() => setitemid(item.id)}>
                                    {item.data.itemname} <br /> <span style={{ color: "#454545" }}>{item.data.itemdesc}</span>
                                  </a>
                                </td>
                                <td><button data-toggle="modal" data-target="#qtyupdate-modal" onClick={() => upOrderQty('Remove', item.id, item.data.itemid, item.data.quantity, item.data.tableid, item.data.tablebillno, item.data.orderid, item.data.rate)} className='btn btn-xs btn-info'><i className='fa fa-minus'></i></button></td>
                                <td>{item.data.quantity}</td>
                                <td><button data-toggle="modal" data-target="#qtyupdate-modal" onClick={() => upOrderQty('Add', item.id, item.data.itemid, item.data.quantity, item.data.tableid, item.data.tablebillno, item.data.orderid, item.data.rate)} className='btn btn-xs btn-info'><i className='fa fa-plus'></i></button></td>
                                <td>{item.data.total}/-</td>
                                <td><button data-toggle="modal" data-target="#reject-modal" onClick={() => deleteOrderItem(item.id, item.data.itemid, item.data.quantity, item.data.tableid, item.data.tablebillno, item.data.orderid, item.data.rate)} className='btn btn-xs btn-danger'><i className='fa fa-trash'></i></button></td>
                              </tr>
                            )
                          }
                        </table>

                      </div><hr />
                      <div className='col-sm-12'>
                        <h6 style={{ marginBottom: 10 }}>Sub Total :  <span style={{ float: "right" }}>Rs. {subtotal}/-</span> </h6>
                        <h6 style={{ marginBottom: 10 }}>Tax :  <span style={{ float: "right" }}>Rs. {tax}/-</span> </h6>
                        <h6 style={{ marginBottom: 10 }}>Total Amount :  <span style={{ float: "right" }}>Rs. {totalamount}/-</span> </h6>
                      </div><hr />
                      <div className='row'>
                        <div className='col-sm-4'>
                          <button onClick={handleKotPrint} className='form-control btn btn-info'>KOT Print  <i className='fa fa-print'></i> </button>
                        </div>
                        {
                          role !== 'Captain' &&
                          <div className='col-sm-4'>
                            <button onClick={() => finaltotal()} data-toggle="modal" data-target="#bill-modal" className='form-control btn btn-success'>Settlement</button>
                          </div>
                        }
                        <div className='col-sm-4'>
                          <button className='form-control btn btn-warning' data-dismiss="modal" aria-label="Close">New Order  </button>
                        </div>
                      </div><br />
                      <div className='row'>
                        <div className='col-sm-4'>
                          <button onClick={handlePrint} className='form-control btn bg-purple'>Preview Bill   </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="modal fade" id="bill-modal">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Create Bill {tablename}</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span></button>
            </div>
            <div className="modal-body">
              <button className="btn btn-info btn-sm" data-toggle="modal" data-target="#customer-modal">Add New Customer</button><hr />
              <div className='row '>
                <div className='col-sm-4'>
                  <label>Select Customer   </label>
                  <select className='form-control' style={{ height: 35, fontSize: 14 }} value={custid} onChange={(e) => setcustid(e.target.value)}>
                    <option></option>
                    {customers.map((item) => (
                      <option value={item.id} key={item.id}>{item.data.name}</option>
                    ))}
                  </select>
                </div>
                <div className="col-sm-4">
                  <label>Bill No </label>
                  <input type="text" className="form-control" style={{ height: 35, fontSize: 14 }} value={billno} onChange={(e) => setbillno(e.target.value)} />
                </div>
                <div className="col-sm-4">
                  <label>Bill Date </label>
                  <input type="date" className="form-control" style={{ height: 35, fontSize: 14 }} value={orderdate} onChange={(e) => setorderdate(e.target.value)} />
                </div>
                <div className="col-sm-4 mt-2">
                  <label>Bill Type </label>
                  <select className='form-control' style={{ height: 35, fontSize: 14 }} value={billtype} onChange={(e) => setbilltype(e.target.value)}>
                    <option value="Regular Bill">Regular Bill</option>
                    <option value="Owner Bill">Owner Bill</option>
                    <option value="Pending Bill">Pending Bill</option>
                  </select>
                </div>
                <div className="col-sm-4 mt-2">
                  <label>Note </label>
                  <textarea className="form-control" value={note} onChange={(e) => setnote(e.target.value)} style={{ height: 35, fontSize: 14 }}></textarea>
                </div>
              </div><br />
              <table className='table table-bordered'>
                <tr>
                  <th>Item Name</th>
                  <th>Quantity</th>
                  <th>Rate</th>
                  <th>Tax</th>
                  <th>Total</th>
                </tr>
                {
                  orderitems.map((item, index) =>
                    <tr key={index}>
                      <td>{item.data.itemname}  </td>
                      <td>{item.data.quantity}</td>
                      <td>{item.data.rate}</td>
                      <td>{item.data.taxamount}</td>
                      <td>{item.data.taxaddedamount}/-</td>
                    </tr>
                  )
                }
              </table>
              <div className='row '>
                <div className='col-sm-3'>
                  <label>Sub Total </label>
                  <input type="text" className='form-control' style={{ height: 35, fontSize: 14 }} readOnly value={subtotal} />
                </div>
                <div className='col-sm-3'>
                  <label>Tax </label>
                  <input type="text" className='form-control' style={{ height: 35, fontSize: 14 }} readOnly value={tax} />
                </div>
                <div className='col-sm-3'>
                  <label>Service Charge </label>
                  <input type="text" className='form-control' style={{ height: 35, fontSize: 14 }} value={servicecharge} onChange={handleServiceCharge} />
                </div>
                <div className='col-sm-3'>
                  <label>Total Amount </label>
                  <input type="text" className='form-control' style={{ height: 35, fontSize: 14 }} readOnly value={totalamount} />
                </div>
                <div className="col-sm-3">
                  <label>Discount</label>
                  <input type="text" className='form-control' style={{ height: 35, fontSize: 14 }} const value={discount} onChange={handleDiscount} placeholder='Enter Paid Amount' />
                </div>
                <div className="col-sm-3">
                  <label>Paid</label>
                  <input type="text" className='form-control' style={{ height: 35, fontSize: 14 }} value={paid} onChange={handlePaid} placeholder='Enter Paid Amount' />
                </div>
                <div className="col-sm-3">
                  <label>Payment Type</label>
                  <select className='form-control' style={{ height: 35, fontSize: 14 }} value={paytype} onChange={(e) => setpaytype(e.target.value)}>
                    <option value="Cash">Cash</option>
                    <option value="UPI">UPI</option>
                    <option value="Credit Card">Credit Card</option>
                    <option value="Debit Card">Debit Card</option>
                  </select>
                </div>
                <div className="col-sm-3">
                  <label>Remaining</label>
                  <input type="text" className='form-control' style={{ height: 35, fontSize: 14 }} value={remaining} readOnly />
                </div>
              </div><br></br>
              <center>
                <button onClick={() => saveBill()} className='btn btn-info'>Save Bill</button>
              </center>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="customer-modal">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Add Customer</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span></button>
            </div>
            <div className="modal-body">
              <div className='row'>
                <div className='col-sm-6'>
                  <label>  Select Bookings</label>
                  <Select options={bookings} value={bookingData} onChange={handleSelectItem3} />
                </div>
              </div><br />
              <div className="row">
                <div className="col-sm-6">
                  <label>  Customer</label>
                  <input type="text" value={name} onChange={(e) => setname(e.target.value)} className="form-control" />
                </div>
                <div className="col-sm-6">
                  <label>Mobile</label>
                  <input type="text" value={mobile} onChange={(e) => setmobile(e.target.value)} className="form-control" />
                </div>
                <div className="col-sm-6 mt-2">
                  <label>Address</label>
                  <input type="text" value={address} onChange={(e) => setaddress(e.target.value)} className="form-control" />
                </div>
              </div><br />
            </div>
            <div className="modal-footer justify-content-between">
              <button type="button" onClick={() => handleSubmit()} className="btn btn-primary" data-dismiss="modal">Save </button>
              <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="bill-modal">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Print Bill {tablename}</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span></button>
            </div>
            <div className="modal-body">
              <div style={{ padding: 20 }} ref={printRef}>
                <div className='col-sm-5'>
                  <center>
                    <h2 style={{ textTransform: "uppercase" }}>THE SKYLAND  </h2>
                    <span style={{ fontSize: 15, color: "black" }}>Near New Bus Stand, Latur Road, Tuljapur, Dist. Dharashiv, 413 601</span><br />
                    <span style={{ fontSize: 15, color: "black" }}>Mobile No: 7077779163, 7077779164, 7077779165 </span><br />
                    <span style={{ fontSize: 15, color: "black" }}>GSTIN No: 27AAWFP8677Q1ZN </span><br />
                  </center>
                  <br />
                  <table width="100%">
                    <tr>
                      <td style={{ color: "black", fontSize: 15 }}>Bill No : {billno} </td>
                      <td style={{ textAlign: "right", color: "black", fontSize: 15 }}>Table No : {tablename}</td>
                    </tr>
                    <tr >
                      <td style={{ color: "black", fontSize: 15 }}>Date :  {moment(orderdate).format('DD/MM/YYYY')} </td>
                      <td style={{ textAlign: "right", color: "black", fontSize: 15 }}>Time :  {moment().format('HH:mm:ss A')} </td>
                    </tr>
                    <tr >
                      <td colSpan={2} style={{ color: "black", fontSize: 15 }}>No of Person :  {nofopax} </td>
                    </tr>
                    <tr >
                      <td style={{ color: "black", fontSize: 15 }}>Guest Name  :  {customer.name} </td>
                      <td style={{ textAlign: "right", color: "black", fontSize: 15 }}>Mobile  :  {customer.mobile} </td>
                    </tr>
                  </table>
                  <hr />
                  <table width="100%">
                    <tr>
                      <th style={{ fontSize: 17, color: "black" }}>Item Name</th>
                      <th style={{ fontSize: 17, color: "black" }}>Qty</th>
                      <th style={{ fontSize: 17, color: "black" }}>Rate</th>
                      <th style={{ fontSize: 17, color: "black" }}>Total</th>
                    </tr>
                    {
                      orderitems.map((item, index) =>
                        <tr key={index}>
                          <td style={{ fontSize: 17, color: "black" }}>{item.data.itemname}  </td>
                          <td style={{ fontSize: 17, color: "black" }}>{item.data.quantity}</td>
                          <td style={{ fontSize: 17, color: "black" }}>{item.data.rate}</td>
                          <td style={{ fontSize: 17, color: "black" }}>{item.data.total}/-</td>
                        </tr>
                      )
                    }
                  </table>
                  <hr />
                  <h6 style={{ marginBottom: 10 }}>Sub Total :  <span style={{ float: "right" }}> {subtotal}</span> </h6>
                  <h6 style={{ marginBottom: 10 }}>CGST (2.5%) :  <span style={{ float: "right" }}> {tax / 2}</span> </h6>
                  <h6 style={{ marginBottom: 10 }}>SGST (2.5%) :  <span style={{ float: "right" }}> {tax / 2}</span> </h6>
                  <h4 style={{ marginBottom: 10 }}>Total Amount :  <span style={{ float: "right" }}> {totalamount}</span> </h4>
                  <hr />
                  <center>
                    <h4>Thank You</h4>
                  </center>
                  <span style={{ fontSize: 15, color: "black" }}>Prepared By  : {username}</span><br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="bill-modal1">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">KOT PRINT {tablename}</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span></button>
            </div>
            <div className="modal-body">
              {/* ==================================== Print Bill ==================================== */}

              <div style={{ padding: 20 }} ref={printRef1}>
                <div className='col-sm-4'>
                  <center>
                    <h2 style={{ textTransform: "uppercase", fontWeight: "700" }}>THE SKYLAND  </h2>
                    <hr />
                    {/* <span>TULJAPUR</span><br />
                    <span>Mobile No:</span> */}
                  </center>
                  <table width="100%">
                    <tr>
                      <td style={{ color: "black", fontSize: 16 }}>Bill No : {billno} </td>
                      <td style={{ textAlign: "right", color: "black", fontSize: 16 }}>Table No : {tablename}</td>
                    </tr>
                    <tr >
                      <td style={{ color: "black", fontSize: 16 }}>Date :  {moment(orderdate).format('DD/MM/YYYY')} </td>
                      <td style={{ textAlign: "right", color: "black", fontSize: 16 }}>Time :  {moment().format('HH:mm:ss A')} </td>
                    </tr>
                    <tr >
                      <td colSpan={2} style={{ color: "black", fontSize: 16 }}>No of Person :  {nofopax} </td>
                    </tr>
                    <tr >
                      <td colSpan={2} style={{ color: "black", fontSize: 16 }}>Printed By :  {username} </td>
                    </tr>
                  </table>
                  <hr />
                  <table width="100%">
                    <tr>
                      <th style={{ fontSize: 22 }}>Item Name</th>
                      <th style={{ textAlign: "right", fontSize: 22 }}>Qty</th>
                    </tr>
                  </table>
                  <hr />
                  <table width="100%">
                    {
                      printorderitems.map((item, index) =>
                        <tr key={index}>
                          <td style={{ fontSize: 22 }}>{item.data.itemname} <br /> <i style={{ color: "#454545" }}>{item.data.itemdesc}</i>  </td>
                          <td style={{ textAlign: "right", fontSize: 22, verticalAlign: "top" }}>{item.qty}</td>
                        </tr>
                      )
                    }
                  </table>
                  <br />
                  <br />
                  <hr />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="reject-modal">
        <div className="modal-dialog modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Enter Rejection for Remove Item</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span></button>
            </div>
            <div className="modal-body">
              <div >
                <div className='col-sm-12'>
                  <label>Enter Note</label>
                  <input className='form-control' value={removeNote} onChange={(e) => setremoveNote(e.target.value)} />
                </div>
              </div>
            </div>
            <div className="modal-footer justify-content-between">
              <button type="button" onClick={() => handleRemoveItem()} className="btn btn-primary" data-dismiss="modal">Save </button>
              <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="itemdesc-modal">
        <div className="modal-dialog modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Add Item Remark</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span></button>
            </div>
            <div className="modal-body">
              <div >
                <div className='col-sm-12'>
                  <label>Enter Remark</label>
                  <input className='form-control' value={itemdesc} onChange={(e) => setitemdesc(e.target.value)} />
                </div>
              </div>
            </div>
            <div className="modal-footer justify-content-between">
              <button type="button" onClick={() => addRemark()} className="btn btn-primary" data-dismiss="modal">Save </button>
              <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="qtyupdate-modal">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">{updateQtys[0]} Qty</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span></button>
            </div>
            <div className="modal-body">
              <div className='row'>
                <div className='col-sm-4'>
                  <label>{updateQtys[3]} Item Ready in Kitchen</label>
                  <select className='form-control' value={itemReady} onChange={(e) => setitemReady(e.target.value)}>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>
                <div className='col-sm-4'>
                  <label>{updateQtys[0]} Qty</label>
                  <input type='number' className='form-control' value={newQty} onChange={(e) => setnewQty(e.target.value)} />
                </div>
                {
                  updateQtys[0] === 'Remove' &&
                  <div className='col-sm-4'>
                    <label>Remove Qty Note</label>
                    <input type='text' className='form-control' value={removeNote} onChange={(e) => setremoveNote(e.target.value)} />
                  </div>
                }
              </div>
            </div>
            <div className="modal-footer justify-content-between">
              <button type="button" onClick={() => handleUpdateItem()} className="btn btn-primary" data-dismiss="modal">Save </button>
              <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>

    </div >
  )
}

export default Tableorders
