import React, { useEffect, useState, useRef } from 'react'
import '.././App.css';
import Swal from 'sweetalert2';
import { auth, db } from '../firebase';
import { collection, addDoc, Timestamp, query, orderBy, onSnapshot, getDoc, getDocs, deleteDoc, doc, setDoc, where, limit, Firestore, getAggregateFromServer, sum, updateDoc } from 'firebase/firestore'
import Select from 'react-select'

let propid = localStorage.getItem('propid');
export default function AddPurchase() {
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });
  const [userData, setUserData] = useState();

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setUserData(user);
    });
  }, [userData]);




  useEffect(() => {
    fetchProducts();
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    setpurchaseDate(formattedDate);
    fetchPurchaseItems();
  }, []);


  const [rounitid, setrounitid] = useState("");
  const [rostock, setrostock] = useState(0);
  const [rogodawanstock, setrogodawanstock] = useState(0);
  const [roprodcatid, setroprodcatid] = useState("");
  const [roprodname, setroprodname] = useState("");
  const [rorate, setrorate] = useState("");
  const [roexcise, setroexcise] = useState("YES");

  const [restocat, setrestocat] = useState("");

  const [productCat, setproductCat] = useState([]);
  const [productUnitData, setproductUnitData] = useState([]);
  const [products, setproducts] = useState([]);
  const [dealers, setdealers] = useState([]);
  const [purchaseItesms, setpurchaseItesms] = useState([]);

  const [name, setname] = useState("");
  const [mobile, setmobile] = useState("");
  const [address, setaddress] = useState("");

  const [itemid, setitemid] = useState("");
  const [item, setitem] = useState("");
  const [qty, setqty] = useState(1);
  const [rate, setrate] = useState("");
  const [subtotal, setsubtotal] = useState("");
  const [discount, setdiscount] = useState(0);
  const [gst, setgst] = useState(0);
  const [gst_amount, setgst_amount] = useState("");
  const [total_amount, settotal_amount] = useState("");


  const [grandtotal, setgrandtotal] = useState("0");
  const [paidamount, setpaidamount] = useState("0");
  const [remaining, setremaining] = useState("0");

  const [purchaseDocId, setpurchaseDocId] = useState("");
  const [dealer, setdealer] = useState("");
  const [dealerId, setdealerId] = useState("");
  const [purchaseNo, setpurchaseNo] = useState("");
  const [purchaseDate, setpurchaseDate] = useState("");
  const [paytype, setpaytype] = useState("Cash");

  // ::::::::::::::::::::::::::::::::::::::::::::::::::: GET DEALERS DATA FUNCTION STATR ::::::::::::::::::::::::::::::::::::::

  const fetchProducts = async () => {
    try {
      const q = query(collection(db, 'productcats'))
      onSnapshot(q, (querySnapshot) => {
        setproductCat(querySnapshot.docs.map(doc => ({
          id: doc.id,
          data: doc.data()
        })));
      });

      const q1 = query(collection(db, 'products'))
      onSnapshot(q1, async (querySnapshot1) => {

        const combinedData = [];
        for (const doc1 of querySnapshot1.docs) {


          combinedData.push({
            id: doc1.id,
            data: doc1.data(),
            label: doc1.data().prodname,
            value: doc1.id,
          });
        }
        setproducts(combinedData);
      });

      const q2 = query(collection(db, 'productunits'))
      onSnapshot(q2, (querySnapshot2) => {
        setproductUnitData(querySnapshot2.docs.map(doc => ({
          id: doc.id,
          data: doc.data(),

        })));
      });

      const q3 = query(collection(db, 'dealers'))
      onSnapshot(q3, (querySnapshot3) => {
        setdealers(querySnapshot3.docs.map(doc => ({
          id: doc.id,
          data: doc.data(),
          label: doc.data().name,
          value: doc.id,
        })));
      });
    } catch (error) {
      Toast.fire({
        icon: "error",
        title: error.message
      });
    }
  }


  const productInsert = async () => {
    if (!roprodname || !roprodcatid || !rorate) {
      Toast.fire({
        icon: "error",
        title: 'Please fill all the fields!!'
      });
      return;
    }
    let prodcatname = "";
    let unitname = "";

    if (roprodcatid) {
      const docRef = doc(db, "productcats", roprodcatid);
      const docSnap = await getDoc(docRef);
      prodcatname = docSnap.data().catname;
    }
    if (rounitid) {
      const docRef1 = doc(db, "productunits", rounitid);
      const docSnap1 = await getDoc(docRef1);
      unitname = docSnap1.data().unit;
    }

    await addDoc(collection(db, 'products'), {
      propid: propid,
      prodcatid: roprodcatid,
      prodcatname: prodcatname,
      prodname: roprodname,
      unit: rounitid,
      unitname: unitname,
      rate: rorate,
      stock: rostock,
      godawanstock: rogodawanstock,
      excise: roexcise,
      uid: userData.uid,
      created: Timestamp.now()
    });


    setroprodname("");
    Toast.fire({
      icon: "success",
      title: 'Product Added Successfully!!'
    });
  }

  const dealerInsert = async () => {
    if (!name) {
      Toast.fire({
        icon: "error",
        title: 'Please fill all the fields!!'
      });
      return;

    }
    await addDoc(collection(db, 'dealers'), {
      propid: propid,
      name: name,
      mobile: mobile,
      address: address,
      total: 0,
      paid: 0,
      email: "",
      remaining: 0,
      uid: userData.uid,
      created: Timestamp.now()
    });
    setname(""); setmobile(""); setaddress("");
    Toast.fire({
      icon: "success",
      title: 'Dealer Added Successfully!!'
    });
  }

  //  ####################################################### Calculation code ##########################################

  const handleSelectItem = (item) => {
    setitem(item);
    setitemid(item.id);
    caltotal();
  };

  const handleSelectItem1 = (item) => {
    setdealerId(item.id);
    setdealer(item);
  };

  const caltotal = () => {
    let subtotal = parseFloat(qty) * parseFloat(rate) - parseFloat(discount);
    let gstamount = (parseFloat(subtotal) * parseFloat(gst)) / 100;
    let total_amount = parseFloat(subtotal) + parseFloat(gstamount);
    setsubtotal(subtotal);
    setgst_amount(gstamount);
    settotal_amount(total_amount)
  }


  const finaltotal = () => {
    let remainingnew = parseFloat(grandtotal) - parseFloat(paidamount);
    if (remainingnew < 0) {
      Toast.fire({ icon: "error", title: 'Remaining should not be less than 0' });
      setpaidamount("");
      setremaining(grandtotal);
      return;
    }
    if (paidamount === '') {
      setremaining(grandtotal);
    } else {
      setremaining(remainingnew);
    }
  }

  //  ####################################################### Purchase Codes ##########################################

  const fetchPurchaseItems = async (purchaseid) => {
    if (!purchaseid) {
      setpurchaseItesms([]);
      return;
    }
    try {

      const q = query(collection(db, 'purchasesitems'), where("purchaseid", "==", purchaseid));
      onSnapshot(q, (querySnapshot2) => {
        console.log(querySnapshot2.size);
        setpurchaseItesms(querySnapshot2.docs.map(doc => ({
          id: doc.id,
          data: doc.data(),
        })));
      });

      const q1 = query(collection(db, 'purchasesitems'), where("purchaseid", "==", purchaseid));
      const snapshot = await getAggregateFromServer(q1, {
        totalAmount: sum('total_amount')
      });

      setgrandtotal(snapshot.data().totalAmount);
      let gtotal = snapshot.data().totalAmount;
      let remainingnew = parseFloat(gtotal) - parseFloat(paidamount);
      if (remainingnew < 0) {
        Toast.fire({ icon: "error", title: 'Remaining should not be less than 0' });
        setpaidamount(0);
        setremaining(gtotal);
        return;
      }
      if (paidamount === '') {
        setremaining(gtotal);
      } else {
        setremaining(remainingnew);
      }


    } catch (error) {
      console.log(error.message);
    }

  }

  const addPurchaseItems = async () => {
    if (!dealerId) {
      Toast.fire({ icon: "error", title: 'Please Select Dealer' });
      return;
    }
    if (!purchaseDate) {
      Toast.fire({ icon: "error", title: 'Please Select Purchase Date' });
      return;
    }

    if (!itemid || !rate || !qty) {
      Toast.fire({ icon: "error", title: 'Product, Rate, Quantity should not be empty' });
      return;
    }
    if (!total_amount) {
      Toast.fire({ icon: "error", title: 'Please check the Total Amount' });
      return;
    }

    if (!purchaseDocId) {
      const docRef = await addDoc(collection(db, 'purchases'), {
        dealerId: dealerId,
        propid: propid,
        purchaseNo: purchaseNo,
        purchaseDate: purchaseDate,
        grandtotal: grandtotal,
        paidamount: parseFloat(paidamount),
        remaining: remaining,
        paytype: paytype,
        status: 'Pending',
        uid: userData.uid,
        created: Timestamp.now()
      });
      await addDoc(collection(db, 'purchasesitems'), {
        dealerId: dealerId,
        purchaseid: docRef.id,
        purchaseDate: purchaseDate,
        itemname: item.data.prodname + " " + item.data.unitname,
        propid: propid,
        itemid: itemid,
        unitname: item.data.unitname,
        qty: parseFloat(qty),
        rate: rate,
        subtotal: subtotal,
        discount: discount,
        gst: gst,
        gst_amount: gst_amount,
        total_amount: total_amount,
        status: 'Pending',
        uid: userData.uid,
        created: Timestamp.now()
      });
      setitemid(""); setitem(""); setqty(1); setrate(""); setsubtotal(""); setdiscount(0); setgst(0);
      setgst_amount(""); settotal_amount("");

      fetchPurchaseItems(docRef.id);
      setpurchaseDocId(docRef.id);

    } else {
      await setDoc(doc(db, 'purchases', purchaseDocId), {
        propid: propid,
        dealerId: dealerId,
        purchaseNo: purchaseNo,
        purchaseDate: purchaseDate,
        grandtotal: grandtotal,
        paidamount: parseFloat(paidamount),
        remaining: remaining,
        paytype: paytype,
        status: 'Pending',
        uid: userData.uid,
        created: Timestamp.now()
      });
      await addDoc(collection(db, 'purchasesitems'), {
        propid: propid,
        dealerId: dealerId,
        purchaseDate: purchaseDate,
        purchaseid: purchaseDocId,
        itemname: item.data.prodname + " " + item.data.unitname,
        itemid: itemid,
        unitname: item.data.unitname,
        qty: parseFloat(qty),
        rate: rate,
        subtotal: subtotal,
        discount: discount,
        gst: gst,
        gst_amount: gst_amount,
        total_amount: total_amount,
        status: 'Pending',
        uid: userData.uid,
        created: Timestamp.now()
      });

      fetchPurchaseItems(purchaseDocId);
      setpurchaseDocId(purchaseDocId);

      setitemid(""); setitem(""); setqty(1); setrate(""); setsubtotal(""); setdiscount(0); setgst(0);
      setgst_amount(""); settotal_amount("");

    }
  }

  const deleteItems = async (purchaseid, docid) => {
    await deleteDoc(doc(db, "purchasesitems", docid));
    fetchPurchaseItems(purchaseid)
  }
  const storePurchase = async () => {

    if (!dealerId) {
      Toast.fire({ icon: "error", title: 'Please Select Dealer' });
      return;
    }
    if (!purchaseDate) {
      Toast.fire({ icon: "error", title: 'Please Select Purchase Date' });
      return;
    }
    if (!grandtotal || !paidamount) {
      Toast.fire({ icon: "error", title: 'Please check the grand total & paid ammount is correct' });
      return;
    }

    try {
      // --------------------- update status of purchase table --------------------
      await updateDoc(doc(db, 'purchases', purchaseDocId), {
        status: 'Active',
        propid: propid,
        dealerId: dealerId,
        purchaseNo: purchaseNo,
        paiddate: purchaseDate,
        grandtotal: grandtotal,
        paidamount: parseFloat(paidamount),
        remaining: remaining,
        paytype: paytype,
      });

      // --------------------- update product stock --------------------
      const q11 = query(collection(db, 'purchasesitems'), where("purchaseid", "==", purchaseDocId));
      onSnapshot(q11, (querySnapshot3) => {
        querySnapshot3.docs.map(async (item) => {
          const docRef = doc(db, 'products', item.data().itemid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            let newstock = parseFloat(docSnap.data().stock) + parseFloat(item.data().qty);
            await updateDoc(doc(db, 'products', item.data().itemid), {
              stock: newstock,
            });

            await updateDoc(doc(db, 'purchasesitems', item.id), {
              status: "Active",
            });
          }
        }
        )
      });

      // --------------------- Add dealer account paid amount --------------------
      await addDoc(collection(db, 'dealeracchistory'), {
        dealerId: dealerId,
        purchasedocid: purchaseDocId,
        purchaseNo: purchaseNo,
        propid: propid,
        paiddate: purchaseDate,
        grandtotal: grandtotal,
        paidamount: parseFloat(paidamount),
        remaining: remaining,
        paytype: paytype,
        status: 'Active',
        note: 'Paid at the purchase',
        uid: userData.uid,
        created: Timestamp.now()
      });

      const q1 = query(collection(db, 'dealeracchistory'), where("dealerId", "==", dealerId));
      const snapshot = await getAggregateFromServer(q1, {
        paidamount: sum('paidamount')
      });

      const q2 = query(collection(db, 'purchases'), where("dealerId", "==", dealerId));
      const snapshot1 = await getAggregateFromServer(q2, {
        grandtotal: sum('grandtotal')
      });

      await updateDoc(doc(db, "dealers", dealerId), {
        total: snapshot1.data().grandtotal,
        paid: snapshot.data().paidamount,
        remaining: parseFloat(snapshot1.data().grandtotal) - parseFloat(snapshot.data().paidamount),
      });


      Toast.fire({ icon: "success", title: 'Purchase Entry Successfully Added' });
      setpurchaseItesms([]); fetchPurchaseItems();
      setgrandtotal("0"); setpaidamount("0"); setremaining("0"); setpurchaseDocId(""); setdealerId(""); setpurchaseNo("");
      setpaytype("Cash");
      window.location.href = "/add-purchase";

    } catch (error) {
      Toast.fire({ icon: "error", title: 'Something was wrong please try again' });
      console.log(error.message)
    }
  }
  // ================================================================================================================================================
  // ================================================================================================================================================

  return (
    <div>
      <div className="content-wrapper">
        <br />
        <section className="content">
          <div className="container-fluid">
            <button className="btn btn-secondary" style={{ float: "right" }} >|| Purchase Management ||</button>
            <a className="btn btn-warning btn-sm" href="view-purchase">View Purchase</a>&nbsp;
            <button className="btn btn-info btn-sm" data-toggle="modal" data-target="#add-dealer">Add New Dealer</button>&nbsp;
            <button className="btn btn-success btn-sm" data-toggle="modal" data-target="#add-product">Add New Product</button><br /><br />
            <div className="card card-primary card-outline">
              <div className="card-body table-responsive p-3" >
                <form id="form" >
                  <div className='row '>
                    <div className='col-sm-4'>
                      <label>Select Dealer Id  </label>
                      <Select options={dealers} value={dealer} onChange={handleSelectItem1} />
                    </div>
                    <div className="col-sm-4">
                      <label>Purchase No </label>
                      <input type="text" className="form-control" style={{ height: 35, fontSize: 14 }} value={purchaseNo} onChange={(e) => setpurchaseNo(e.target.value)} />
                    </div>
                    <div className="col-sm-4">
                      <label>Purchase Date </label>
                      <input type="date" className="form-control" style={{ height: 35, fontSize: 14 }} value={purchaseDate} onChange={(e) => setpurchaseDate(e.target.value)} />
                    </div>
                  </div>
                  <hr style={{
                    color: 'orangered',
                    backgroundColor: 'orangered',
                    height: .2,
                    borderColor: 'orangered'
                  }} />
                  <div className='row form-group'>
                    <table className='customers'>
                      <thead>
                        <tr>
                          <th width="250"><label>Item</label></th>
                          <th><label>Rate</label></th>
                          <th><label>Qty</label></th>
                          <th><label>Discount</label></th>
                          <th><label>Total </label></th>
                          <th><label>Gst  </label></th>
                          <th><label>Gst Amnt  </label></th>
                          <th><label>All Total</label></th>
                          <th width="60"><label>Action</label></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <Select options={products} value={item} onChange={handleSelectItem} />
                          </td>
                          <td>
                            <input type="text" onKeyUp={() => caltotal()} className="form-control" style={{ height: 35, fontSize: 14 }} value={rate} onChange={(e) => setrate(e.target.value)} placeholder='Rate' />
                          </td>
                          <td>
                            <input type="text" onKeyUp={() => caltotal()} className="form-control" style={{ height: 35, fontSize: 14 }} value={qty} onChange={(e) => setqty(e.target.value)} placeholder='Qty' />
                          </td>
                          <td>
                            <input type="text" onKeyUp={() => caltotal()} className="form-control" style={{ height: 35, fontSize: 14 }} value={discount} onChange={(e) => setdiscount(e.target.value)} placeholder='Dis' />
                          </td>
                          <td>
                            <input type="text" onKeyUp={() => caltotal()} className="form-control" style={{ height: 35, fontSize: 14 }} value={subtotal} onChange={(e) => setsubtotal(e.target.value)} readOnly />
                          </td>
                          <td>
                            <input type="text" onKeyUp={() => caltotal()} className="form-control" style={{ height: 35, fontSize: 14 }} value={gst} onChange={(e) => setgst(e.target.value)} />
                          </td>
                          <td>
                            <input type="text" onKeyUp={() => caltotal()} className="form-control" style={{ height: 35, fontSize: 14 }} value={gst_amount} onChange={(e) => setgst_amount(e.target.value)} />
                          </td>
                          <td>
                            <input type="text" onKeyUp={() => caltotal()} className="form-control" style={{ height: 35, fontSize: 14 }} value={total_amount} onChange={(e) => settotal_amount(e.target.value)} readOnly />
                          </td>
                          <td>
                            &nbsp;<a title='Add Details' onClick={() => addPurchaseItems()} href='#0' className='btn btn-sm  btn-info'><i className='fa fa-plus'></i></a>
                          </td>
                        </tr>
                        {
                          purchaseItesms.map((item, index) =>
                            <tr key={index}>
                              <td>{item.data.itemname}</td>
                              <td>{item.data.rate}</td>
                              <td>{item.data.qty}</td>
                              <td>{item.data.discount}</td>
                              <td>{item.data.subtotal}</td>
                              <td>{item.data.gst}</td>
                              <td>{item.data.gst_amount}</td>
                              <td>{item.data.total_amount}</td>
                              <td>
                                &nbsp;<a title='Delete Entry' onClick={() => deleteItems(item.data.purchaseid, item.id)} href='#0' className='btn btn-sm  btn-danger'><i className='fa fa-trash'></i></a>
                              </td>
                            </tr>

                          )}
                      </tbody>
                    </table>
                  </div>
                  <hr style={{
                    color: 'orangered',
                    backgroundColor: 'orangered',
                    height: .2,
                    borderColor: 'orangered'
                  }} />
                  <div className='row '>
                    <div className='col-sm-6'>
                      <div className='row form-group'>
                        <div className="col-sm-3"><label>Grand Total </label> </div>
                        <div className="col-sm-7">
                          <input type="text" className='form-control' style={{ height: 35, fontSize: 14 }} readOnly value={grandtotal} />
                        </div>
                        <div className='col-sm-2'></div>
                      </div>
                      <div className='row form-group'>
                        <div className="col-sm-3"><label>Paid</label> </div>
                        <div className="col-sm-7">
                          <input type="text" className='form-control' style={{ height: 35, fontSize: 14 }} onKeyUp={() => finaltotal()} value={paidamount} onChange={(e) => setpaidamount(e.target.value)} placeholder='Enter Paid Amount' />
                        </div>
                        <div className='col-sm-2'></div>
                      </div>
                      <div className='row form-group'>
                        <div className="col-sm-3"><label>Payment Type</label> </div>
                        <div className="col-sm-7">
                          <select className='form-control' style={{ height: 35, fontSize: 14 }} value={paytype} onChange={(e) => setpaytype(e.target.value)}>
                            <option value="Cash">Cash</option>
                            <option value="Bank">Bank</option>
                            <option value="RTGS">RTGS</option>
                            <option value="Online Pay">Online Pay</option>
                          </select>
                        </div>
                        <div className='col-sm-2'></div>
                      </div>
                      <div className='row form-group'>
                        <div className="col-sm-3"><label>Remaining</label> </div>
                        <div className="col-sm-7">
                          <input type="text" className='form-control' style={{ height: 35, fontSize: 14 }} value={remaining} readOnly />
                        </div>
                        <div className='col-sm-2'></div>
                      </div>
                    </div>
                  </div><hr />
                  <center><button type='button' onClick={storePurchase} className='btn btn-info'>Save Purchase</button></center>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* ============================================= modal start ================================================= */}

      <div className="modal fade" id="add-product">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Add Product</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span></button>
            </div>
            <div className="modal-body">
              <div className='row'>
                <div className="col-sm-6">
                  <label>Product Category</label>
                  <select value={roprodcatid} onChange={(e) => setroprodcatid(e.target.value)} className="form-control" >
                    <option value=""></option>
                    {
                      productCat.map((item, index) =>
                        <option key={index} value={item.id}>{item.data.catname}</option>
                      )
                    }

                  </select>
                </div>
                <div className="col-sm-6">
                  <label>  Product Name</label>
                  <input type="text" value={roprodname} onChange={(e) => setroprodname(e.target.value)} className="form-control" />
                </div>
                <div className="col-sm-6 mt-2">
                  <label>  Product Units</label>
                  <select value={rounitid} onChange={(e) => setrounitid(e.target.value)} className="form-control" >
                    <option value=""></option>
                    {
                      productUnitData.map((item, index) =>
                        <option key={index} value={item.id}>{item.data.unit}</option>
                      )
                    }
                  </select>
                </div>
                <div className="col-sm-6 mt-2">
                  <label>  Rate</label>
                  <input type="text" value={rorate} onChange={(e) => setrorate(e.target.value)} className="form-control" />
                </div>
                <div className="col-sm-6 mt-2">
                  <label>  Stock</label>
                  <input type="text" value={rostock} onChange={(e) => setrostock(e.target.value)} className="form-control" />
                </div>
                <div className="col-sm-6 mt-2">
                  <label> Godawan Stock</label>
                  <input type="text" value={rogodawanstock} onChange={(e) => setrogodawanstock(e.target.value)} className="form-control" />
                </div>

              </div>
            </div>
            <div className="modal-footer justify-content-between">
              <button type="submit" onClick={() => productInsert()} className="btn btn-primary" data-dismiss="modal">Save </button>
              <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>


      <div className="modal fade" id="add-dealer">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Add Dealer</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-sm-6">
                  <label>  Dealer</label>
                  <input type="text" value={name} onChange={(e) => setname(e.target.value)} className="form-control" style={{ height: 35, fontSize: 14 }} />
                </div>
                <div className="col-sm-6">
                  <label>Mobile</label>
                  <input type="text" value={mobile} onChange={(e) => setmobile(e.target.value)} className="form-control" style={{ height: 35, fontSize: 14 }} />
                </div>
                <div className="col-sm-6 mt-2">
                  <label>Address</label>
                  <input type="text" value={address} onChange={(e) => setaddress(e.target.value)} className="form-control" style={{ height: 35, fontSize: 14 }} />
                </div>
              </div><br />
            </div>
            <div className="modal-footer justify-content-between">
              <button type="button" onClick={() => dealerInsert()} className="btn btn-primary" data-dismiss="modal">Save </button>
              <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}
