import React, { useEffect, useState, useRef } from 'react'
import '.././App.css';
import Swal from 'sweetalert2';
import { auth, db } from '../firebase';
import { collection, addDoc, Timestamp, query, orderBy, onSnapshot, getDoc, getDocs, deleteDoc, doc, setDoc, where, updateDoc } from 'firebase/firestore'

let propid = localStorage.getItem('propid');
export default function Cheforders() {
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });
  const [userData, setUserData] = useState();
  const [orderitems, setorderitems] = useState([]);

  useEffect(() => {
    fetchData1()
  }, []);



  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setUserData(user);
    });
  });

  const fetchData1 = async () => {
    try {
      const q = query(collection(db, 'orderitems'), where('propid', '==', propid), where('kot', '==', 'YES'), 
      where('status', '==', 'Pending'))
      onSnapshot(q, (querySnapshot) => {
        setorderitems(querySnapshot.docs.map(doc => ({
          id: doc.id,
          data: doc.data()
        })));
      });
    } catch (error) {
      Toast.fire({
        icon: "error",
        title: error.message
      });
    }
  }

  const orderComlete = async (docid) => {
    await updateDoc(doc(db, 'orderitems', docid), {
      status: Timestamp.now(),
    });

    Toast.fire({
      icon: "success",
      title: 'Order  Successfully Completed!!'
  });

  }

  return (
    <div>
      <div className="content-wrapper">
        <br />
        <section className="content">
          <div className="container-fluid">
            <button className="btn btn-secondary " style={{ float: "right" }} >|| KOT Orders ||</button><br /><br />
            <div className="card card-primary card-outline ">
              <div className="card-body " >
                <table className='table'>
                  <tr>
                    <th width="120">Item Name</th>
                    <th width="30">Qty</th>
                    <th width="30">Bill No</th>
                    <th width="50">Action</th>
                  </tr>
                  {
                    orderitems.map((item, index) =>
                      <tr key={index}>
                        <td>{item.data.itemname}  </td>
                        <td>{item.data.quantity}</td>
                        <td>{item.data.billno}</td>
                        <td>
                          <a  onClick={() => { if (window.confirm('Are u sure to complete order?')) { orderComlete(item.id) }; }} className="btn btn-sm btn-success" href='#0'> <i className="fa fa-check"></i> Food Ready</a>&nbsp;

                        </td>
                      </tr>
                    )
                  }
                </table>
              </div>
            </div>
          </div>
        </section>
      </div >

    </div >
  )
}
