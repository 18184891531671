import React, { useEffect, useState } from 'react'
import '.././App.css';
import Swal from 'sweetalert2';
import { db } from '../firebase';
import { collection, query, onSnapshot, orderBy } from 'firebase/firestore'

export default function Itemnotes() {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });
    const [notes, setnotes] = useState([]);

    useEffect(() => {
        fetchData1()
    }, []);

    const fetchData1 = async () => {
        try {
            const q = query(collection(db, 'rejectionnote'), orderBy('created', 'desc'))
            onSnapshot(q, (querySnapshot) => {
                setnotes(querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })));
            });
        } catch (error) {
            Toast.fire({
                icon: "error",
                title: error.message
            });
        }
    }



    return (
        <div>
            <div className="content-wrapper">
                <br />
                <section className="content">
                    <div className="container-fluid">
                        <button className="btn btn-secondary " style={{ float: "right" }} >|| Item Cancellation ||</button><br/><br/>
                        <div className="card card-primary card-outline ex1">
                            <div className="card-body table-responsive p-0" >
                                <table className="table table-bordered table-hover table-striped table-head-fixed">
                                    <thead>
                                        <tr>
                                            <th>Sr No</th>
                                            <th>Item Name</th>
                                            <th>Table No</th>
                                            <th>Quantity</th>
                                            <th>Note</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            notes.map((item, num = 1) =>
                                                <tr key={num + 1}>
                                                    <td width="80">{num + 1}</td>
                                                    <td>{item.data.itemname}</td>
                                                    <td>{item.data.tableno}</td>
                                                    <td>{item.data.quantity}</td>
                                                    <td>{item.data.removeNote}</td>
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </div>


        </div>
    )
}
