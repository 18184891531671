import React, { useEffect, useState, useRef } from 'react'
import '.././App.css';
import Swal from 'sweetalert2';
import { auth, db } from '../firebase';
import { collection, addDoc, Timestamp, query, orderBy, onSnapshot, getDoc, getDocs, deleteDoc, doc, setDoc, where, limit } from 'firebase/firestore'

let propid = localStorage.getItem('propid');
export default function SeatingDetails() {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });
    const [userData, setUserData] = useState();
    const [seatingCat, setseatingCat] = useState([]);
    const [seatings, setseatings] = useState([]);
    const [searchText, setsearchText] = useState("");

    const [seatcatid, setseatcatid] = useState("");
    const [seatname, setseatname] = useState("");
    const [status, setstatus] = useState("");

    const [updocid, setupdocid] = useState("");
    const [upseatcatid, setupseatcatid] = useState("");
    const [upSeatingdetails, setupSeatingdetails] = useState("");
    const [upcstatus, setupcstatus] = useState("");

    useEffect(() => {
        if (searchText === '') {
            fetchData1()
        } else {
            fetchData2()
        }
    }, [searchText]);



    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            setUserData(user);
        });
    });

    const fetchData1 = async () => {
        try {
            const q = query(collection(db, 'seatingcats'), orderBy("created", "desc"))
            onSnapshot(q, (querySnapshot) => {
                setseatingCat(querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })));
            });

            const q1 = query(collection(db, 'seatings'), orderBy("created", "desc"))
            onSnapshot(q1, async (querySnapshot1) => {

                const combinedData = [];
                let catname = "";
                for (const doc1 of querySnapshot1.docs) {
                    if (doc1.data().seatcatid) {
                        const docRef = doc(db, "seatingcats", doc1.data().seatcatid);
                        const docSnap = await getDoc(docRef);
                        catname = docSnap.data().catname;
                    }
                    else {
                        catname = "";
                    }

                    combinedData.push({
                        id: doc1.id,
                        data: doc1.data(),
                        catname: catname,
                    });
                }
                setseatings(combinedData);
            });

        } catch (error) {
            Toast.fire({
                icon: "error",
                title: error.message
            });
        }
    }

    const fetchData2 = async () => {
        console.log(4545)
        try {
            if (seatings) {
                const filteredData = seatings.filter((item) =>
                    item.data.seatname.toUpperCase().includes(searchText.toUpperCase())
                );
                setseatings(filteredData);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }


    const handleSubmit = async () => {
        if (!seatname || !seatcatid) {
            Toast.fire({
                icon: "error",
                title: 'Please fill all the fields!!'
            });
            return;
        }

        await addDoc(collection(db, 'seatings'), {
            seatcatid: seatcatid,
            seatname: seatname,
            uid: userData.uid,
            propid: propid,
            created: Timestamp.now()
        });


        setseatcatid("");
        setseatname("");
        Toast.fire({
            icon: "success",
            title: 'Seating Details Added Successfully!!'
        });
    }

    const handleDelete = async (docid) => {
        const taskDocRef = doc(db, 'seatings', docid)
        await deleteDoc(taskDocRef)
        Toast.fire({
            icon: "success",
            title: 'Seating Details Deleted Successfully!!'
        });
        fetchData1();
    }

    const handleEdit = (docid, category, seatname, unit) => {
        setupdocid(docid);
        setupSeatingdetails(seatname);
        setupseatcatid(category);
    }

    const handleUpdate = async () => {
        try {
            await setDoc(doc(db, "seatings", updocid), {
                seatcatid: upseatcatid,
                seatname: upSeatingdetails,
                propid: propid,
                uid: userData.uid,
                created: Timestamp.now()
            });
            Toast.fire({
                icon: "success",
                title: 'Seating Details Updated Successfully!!'
            });

            fetchData1();
        } catch (error) {
            console.log(error.message);
        }

    }



    return (
        <div>
            <div className="content-wrapper">
                <br />
                <section className="content">
                    <div className="container-fluid">
                        <button className="btn btn-secondary " style={{ float: "right" }} >|| Seating Details ||</button>
                        <button className="btn btn-info btn-sm" data-toggle="modal" data-target="#insert-modal">Add Seating Details</button><br /><br />
                        <div className="card card-primary card-outline ex1">
                            <div className=" p-1">
                                &nbsp;&nbsp;<input type="search" value={searchText} onChange={(e) => setsearchText(e.target.value)}
                                    className='form-control col-sm-4' placeholder='Type here......' />&nbsp;
                            </div>
                            <div className="card-body table-responsive p-0" >
                                <table className="table table-bordered table-hover table-striped table-head-fixed">
                                    <thead>
                                        <tr>
                                            <th>Sr No</th>
                                            <th>Seating Category</th>
                                            <th>Seating Details</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            seatings.map((item, num = 1) =>
                                                <tr key={num + 1}>
                                                    <td width="80">{num + 1}</td>
                                                    <td>{item.catname}</td>
                                                    <td>{item.data.seatname}</td>
                                                    <td>
                                                        <a onClick={() => handleEdit(item.id, item.data.seatcatid, item.data.seatname, item.data.unit)} href='#0' data-toggle="modal" data-target="#text-modal1" className="btn btn-sm btn-success" > <i className="fa fa-edit"></i></a>&nbsp;
                                                        <a  onClick={() => { if (window.confirm('Are u sure?')) { handleDelete(item.id) }; }} className="btn btn-sm btn-danger" href='#0' > <i className="fa fa-trash"></i></a>
                                                    </td>
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* ============================================= modal start ================================================= */}

            <div className="modal fade" id="insert-modal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Add Seating Details</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Seating Category</label>
                                    <select value={seatcatid} onChange={(e) => setseatcatid(e.target.value)} className="form-control" >
                                        <option value=""></option>
                                        {
                                            seatingCat.map((item, index) =>
                                                <option key={index} value={item.id}>{item.data.catname}</option>
                                            )
                                        }

                                    </select>
                                </div>
                                <div className="col-sm-6">
                                    <label>Seating Name</label>
                                    <input type="text" value={seatname} onChange={(e) => setseatname(e.target.value)} className="form-control" />
                                </div>
                            </div><br />
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="submit" onClick={() => handleSubmit()} className="btn btn-primary" data-dismiss="modal">Save </button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal fade" id="text-modal1">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Edit Seating Details</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Seating Category</label>
                                    <select value={upseatcatid} onChange={(e) => setupseatcatid(e.target.value)} className="form-control" >
                                        <option value=""></option>
                                        {
                                            seatingCat.map((item, index) =>
                                                <option key={index} value={item.id}>{item.data.catname}</option>
                                            )
                                        }
                                    </select>
                                </div>
                                <div className="col-sm-6">
                                    <label> Seating Details</label>
                                    <input type="text" value={upSeatingdetails} onChange={(e) => setupSeatingdetails(e.target.value)} className="form-control" placeholder="Enter  Item Name ..." />
                                </div>
                            </div><br />
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" onClick={() => handleUpdate()} className="btn btn-primary" data-dismiss="modal">Update </button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
