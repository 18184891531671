import React, { useEffect, useState, useRef } from 'react'
import '.././App.css';
import Swal from 'sweetalert2';
import { auth, db } from '../firebase';
import { collection, addDoc, Timestamp, query, orderBy, onSnapshot, getDoc, getDocs, deleteDoc, doc, setDoc, where } from 'firebase/firestore'

let propid = localStorage.getItem('propid');
export default function DealerDetails() {
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });
  const [userData, setUserData] = useState();
  const [dealers, setdealers] = useState([]);
  const [searchText, setsearchText] = useState("");
  const [name, setname] = useState("");
  const [mobile, setmobile] = useState("");
  const [address, setaddress] = useState("");
  const [email, setemail] = useState("");
  const [total, settotal] = useState(0);

  const [updocid, setupdocid] = useState("");
  const [upname, setupname] = useState("");
  const [upmobile, setupmobile] = useState("");
  const [upaddress, setupaddress] = useState("");
  const [upemail, setupemail] = useState("");
  const [uptotal, setuptotal] = useState(0);

  useEffect(() => {
    if (searchText === '') {
      fetchData1()
    } else {
      fetchData2()
    }
  }, [searchText]);



  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setUserData(user);
    });
  });

  const fetchData1 = async () => {
    try {
      const q = query(collection(db, 'dealers'))
      onSnapshot(q, (querySnapshot) => {
        setdealers(querySnapshot.docs.map(doc => ({
          id: doc.id,
          data: doc.data()
        })));
      });
    } catch (error) {
      Toast.fire({
        icon: "error",
        title: error.message
      });
    }
  }

  const fetchData2 = async () => {
    try {
      const filteredData = dealers.filter((item) =>
        item.data.name.toUpperCase().includes(searchText.toUpperCase())
      );
      dealers(filteredData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }



  const handleSubmit = async () => {
    await addDoc(collection(db, 'dealers'), {
      name: name,
      mobile: mobile,
      address: address,
      propid: propid,
      total: parseFloat(total),
      paid: 0,
      email: email,
      remaining: parseFloat(total),
      uid: userData.uid,
      created: Timestamp.now()
    });
    setname(""); setmobile(""); setaddress(""); setemail(""); settotal(0);
    Toast.fire({
      icon: "success",
      title: 'Dealer Added Successfully!!'
    });
  }

  const handleDelete = async (docid) => {
    console.log(docid);
    const taskDocRef = doc(db, 'dealers', docid)
    await deleteDoc(taskDocRef)
    Toast.fire({
      icon: "success",
      title: 'Dealer Deleted Successfully!!'
    });
    fetchData1();
  }

  const handleEdit = (docid, name, mobile, address, email, outstanding) => {
    setupdocid(docid);
    setupname(name);
    setupmobile(mobile);
    setupaddress(address);
    setupemail(email);
    setuptotal(outstanding);
  }

  const handleUpdate = async () => {
    await setDoc(doc(db, "dealers", updocid), {
      name: upname,
      mobile: upmobile,
      address: upaddress,
      propid: propid,
      total: parseFloat(uptotal),
      paid: 0,
      email: upemail,
      remaining: parseFloat(uptotal),
      uid: userData.uid,
      created: Timestamp.now()
    });
    Toast.fire({
      icon: "success",
      title: 'Dealer Updated Successfully!!'
    });

    fetchData1();
  }

  return (
    <div>
      <div className="content-wrapper">
        <br />
        <section className="content">
          <div className="container-fluid">
            <button className="btn btn-secondary " style={{ float: "right" }} >|| Dealer  Mgmt||</button>
            <button className="btn btn-info btn-sm" data-toggle="modal" data-target="#insert-modal">Add Dealer</button><br /><br />
            <div className="card card-primary card-outline ex1">
              <div className=" p-1">
                &nbsp;&nbsp;<input type="search" value={searchText} onChange={(e) => setsearchText(e.target.value)}
                  className='form-control col-sm-4' placeholder='Type here......' />&nbsp;
              </div>
              <div className="card-body table-responsive p-0" >
                <table className="table table-bordered table-hover table-striped table-head-fixed">
                  <thead>
                    <tr>
                      <th>Sr No</th>
                      <th>Dealer Name</th>
                      <th>Mobile</th>
                      <th>Address</th>
                      <th>Email</th>
                      {/* <th>Outstanding</th> */}
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      dealers.map((item, num = 1) =>
                        <tr key={num + 1}>
                          <td width="80">{num + 1}</td>
                          <td>{item.data.name}</td>
                          <td>{item.data.mobile}</td>
                          <td>{item.data.address}</td>
                          <td>{item.data.email}</td>
                          {/* <td>{item.data.total}</td> */}
                          <td>
                            <a onClick={() => handleEdit(item.id, item.data.name, item.data.mobile, item.data.address, item.data.email, item.data.total)} href='#0' data-toggle="modal" data-target="#text-modal1" className="btn btn-sm btn-success" > <i className="fa fa-edit"></i></a>&nbsp;
                            <a onClick={() => { if (window.confirm('Are u sure?')) { handleDelete(item.id) }; }} className="btn btn-sm btn-danger" href='#0' > <i className="fa fa-trash"></i></a>
                          </td>
                        </tr>
                      )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* ============================================= modal start ================================================= */}

      <div className="modal fade" id="insert-modal">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Add Dealer</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-sm-6">
                  <label>  Dealer</label>
                  <input type="text" value={name} onChange={(e) => setname(e.target.value)} className="form-control" />
                </div>
                <div className="col-sm-6">
                  <label>Mobile</label>
                  <input type="text" value={mobile} onChange={(e) => setmobile(e.target.value)} className="form-control" />
                </div>
                <div className="col-sm-6 mt-2">
                  <label>Address</label>
                  <input type="text" value={address} onChange={(e) => setaddress(e.target.value)} className="form-control" />
                </div>
                <div className="col-sm-6 mt-2">
                  <label>Email </label>
                  <input type="text" value={email} onChange={(e) => setemail(e.target.value)} className="form-control" />
                </div>

              </div><br />
            </div>
            <div className="modal-footer justify-content-between">
              <button type="button" onClick={() => handleSubmit()} className="btn btn-primary" data-dismiss="modal">Save </button>
              <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>


      <div className="modal fade" id="text-modal1">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Edit Dealer</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-sm-6">
                  <label> Dealer</label>
                  <input type="text" value={upname} onChange={(e) => setupname(e.target.value)} className="form-control" placeholder="Enter  Item Name ..." />
                </div>
                <div className="col-sm-6">
                  <label>Mobile</label>
                  <input type="text" value={upmobile} onChange={(e) => setupmobile(e.target.value)} className="form-control" />
                </div>
                <div className="col-sm-6 mt-2">
                  <label>Address</label>
                  <input type="text" value={upaddress} onChange={(e) => setupaddress(e.target.value)} className="form-control" />
                </div>
                <div className="col-sm-6 mt-2">
                  <label>Email</label>
                  <input type="text" value={upemail} onChange={(e) => setupemail(e.target.value)} className="form-control" />
                </div>

              </div><br />
            </div>
            <div className="modal-footer justify-content-between">
              <button type="button" onClick={() => handleUpdate()} className="btn btn-primary" data-dismiss="modal">Update </button>
              <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
