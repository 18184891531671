import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCHKoB3k2vfkNS3m8y2jE1ObUGsK5dWzjw",
  authDomain: "skyland-6d5d7.firebaseapp.com",
  projectId: "skyland-6d5d7",
  storageBucket: "skyland-6d5d7.appspot.com",
  messagingSenderId: "743983694907",
  appId: "1:743983694907:web:864e35ed3de61fdbb7081e",
  measurementId: "G-2B74HNZHWY" 
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth();
const db = getFirestore(app);


export { db, auth };

export default app;