import React, { useEffect, useState, useRef } from 'react'
import '.././App.css';
import Swal from 'sweetalert2';
import { auth, db } from '../firebase';
import { collection, addDoc, Timestamp, query, orderBy, onSnapshot, getDoc, getDocs, deleteDoc, doc, setDoc, where, limit, getAggregateFromServer, sum, getCountFromServer } from 'firebase/firestore'
import moment from 'moment';

let propid = localStorage.getItem('propid');
export default function ExiseReport() {
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });
  const [loading, setloading] = useState(false);
  const [userData, setUserData] = useState();
  const [searchText, setsearchText] = useState("");
  const [products, setproducts] = useState([]);
  const [exisereports, setexisereports] = useState([]);
  const [reportData, setreportData] = useState([]);
  const [date, setdate] = useState("");
  const [reportdate, setreportdate] = useState("");

  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    setdate(formattedDate);
    fetchData1()
  }, [searchText]);



  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setUserData(user);
    });
  });

  const fetchData1 = async () => {
    try {
      const q = query(collection(db, 'excisereport'), where("propid", '==', propid))
      onSnapshot(q, (querySnapshot) => {
        setexisereports(querySnapshot.docs.map(doc => ({
          id: doc.id,
          data: doc.data()
        })));
      });

      const q11 = query(collection(db, 'products'), where("propid", '==', propid), where('excise', "==", "YES"))
      onSnapshot(q11, (querySnapshot) => {
        setproducts(querySnapshot.docs.map(doc => ({
          id: doc.id,
          data: doc.data()
        })));
      });


    } catch (error) {
      Toast.fire({
        icon: "error",
        title: error.message
      });
    }
  }



  const handleSubmit = async () => {

    if (!date) {
      Toast.fire({ icon: "error", title: 'Please select date' });
      return;
    }

    setloading(true);

    var cdate = new Date(date);

    let prevDate1 = cdate.setDate(cdate.getDate() - 1);
    let prevDate = moment(prevDate1).format("YYYY-MM-DD");

    const q = query(collection(db, "exisereport"), where("date", "==", date));
    const querySnapshot2 = await getDocs(q);
    if (!querySnapshot2.empty) {
      Toast.fire({ icon: "error", title: 'Excise Report already generated of the date ' + date });
      return;
    }

    const docRef = await addDoc(collection(db, 'excisereport'), {
      propid: propid,
      date: date,
      status: "Active",
      uid: userData.uid,
      created: Timestamp.now()
    });

    if (products) {
      products.map(async (item) => {
        let ropeningstock = 0;
        let rpurchasestock = 0;
        let rsalestock = 0;
        let rclosingstock = 0;

        const openingq = query(collection(db, 'excisestock'), where("productid", "==", item.id),
          where("date", "==", prevDate));
        const openingSnap = await getDocs(openingq);
        if (!openingSnap.empty) {
          const openingsnapshot = await getAggregateFromServer(openingq, {
            openingstock: sum('closingunitstock')
          });
          ropeningstock = openingsnapshot.data().openingstock;
        } else {
          await addDoc(collection(db, 'excisestock'), {
            excisereportid: "",
            date: prevDate,
            prodname: item.data.prodname + " " + item.data.unitname + " (" + item.data.prodcatname + ")" || null,
            productid: item.id,
            unitid: item.data.unit,
            openingunitstock: item.data.stock,
            purchaseunitstock: 0,
            saleunitstock: 0,
            closingunitstock: item.data.stock,
            uid: userData.uid,
            created: Timestamp.now()
          });

          const openingq = query(collection(db, 'excisestock'), where("productid", "==", item.id),
            where("date", "==", prevDate));
          const openingSnap = await getDocs(openingq);
          if (!openingSnap.empty) {
            const openingsnapshot = await getAggregateFromServer(openingq, {
              openingstock: sum('closingunitstock')
            });
            ropeningstock = openingsnapshot.data().openingstock;
          }
        }
        const purchaseq = query(collection(db, 'purchasesitems'), where("itemid", "==", item.id),
          where("status", '==', 'Active'), where("purchaseDate", "==", date));
        const purchaseSnap = await getDocs(purchaseq);
        if (!purchaseSnap.empty) {
          const purchasesnapshot = await getAggregateFromServer(purchaseq, {
            qty: sum('qty')
          });
          rpurchasestock = purchasesnapshot.data().qty;
        }

        const saleq = query(collection(db, 'orderitems'), where("itemid", "==", item.id),
          where("orderdate", "==", date));
        const saleSnap = await getDocs(saleq);
        if (!saleSnap.empty) {
          const salesnapshot = await getAggregateFromServer(saleq, {
            quantity: sum('quantity')
          });
          rsalestock = salesnapshot.data().quantity;
        }

        rclosingstock = parseFloat(ropeningstock) + parseFloat(rpurchasestock) - parseFloat(rsalestock);

        await addDoc(collection(db, 'excisestock'), {
          excisereportid: docRef.id,
          date: date,
          prodname: item.data.prodname + " " + item.data.unitname + " (" + item.data.prodcatname + ")" || null,
          productid: item.id,
          unitid: item.data.unit,
          openingunitstock: ropeningstock,
          purchaseunitstock: rpurchasestock,
          saleunitstock: rsalestock,
          closingunitstock: rclosingstock,
          uid: userData.uid,
          created: Timestamp.now()
        });
      });
    }
    setloading(false);

    setdate("");
    Toast.fire({
      icon: "success",
      title: 'Excise Report Successfully Generated!!'
    });
  }

  const handleDelete = async (docid) => {
    const taskDocRef = doc(db, 'excisereport', docid)
    await deleteDoc(taskDocRef)

    const q11 = query(collection(db, 'excisestock'), where("excisereportid", "==", docid));
    onSnapshot(q11, (querySnapshot3) => {
      querySnapshot3.docs.map(async (item) => {
        const taskDocRef1 = doc(db, 'excisestock', item.id)
        await deleteDoc(taskDocRef1)
      }
      )
    });
    Toast.fire({
      icon: "success",
      title: 'Excise Report Deleted Successfully!!'
    });
    fetchData1();
  }


  const printReport = async (docid, rdate) => {
    setreportdate(rdate)
    setreportData([])
    const q1 = query(collection(db, 'excisestock'), orderBy('prodname', 'asc'), where('date', "==", rdate))
    onSnapshot(q1, async (querySnapshot1) => {
      const combinedData = [];
      for (const doc1 of querySnapshot1.docs) {
        combinedData.push({
          id: doc1.id,
          data: doc1.data(),
        });
      }
      setreportData(combinedData);
    });

  }


  return (
    <div>
      <div className="content-wrapper">
        <br />
        <section className="content">
          <div className="container-fluid">
            <button className="btn btn-secondary " onClick={() => handleSubmit()} style={{ float: "right" }} >|| Excise Report ||</button>
            <button className="btn btn-info btn-sm" data-toggle="modal" data-target="#insert-modal">Generate Report</button><br /><br />
            <div className="card card-primary card-outline ex1">
              <div className=" p-1">
                &nbsp;&nbsp;<input type="search" value={searchText} onChange={(e) => setsearchText(e.target.value)}
                  className='form-control col-sm-4' placeholder='Type here......' />&nbsp;
              </div>
              <div className="card-body table-responsive p-0" >
                <table className="table table-bordered table-hover table-striped table-head-fixed">
                  <thead>
                    <tr>
                      <th>Sr No</th>
                      <th>Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      exisereports.map((item, index) =>
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td> {moment(item.data.date).format('DD-MM-YYYY')}</td>
                          <td>
                            <a onClick={() => printReport(item.id, item.data.date)} className="btn btn-sm btn-success" href='#0' data-toggle="modal" data-target="#text-modal1" > <i className="fa fa-print"></i></a>&nbsp;
                            <a onClick={() => handleDelete(item.id)} className="btn btn-sm btn-danger" href='#0' > <i className="fa fa-trash"></i></a>
                          </td>
                        </tr>)
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* ============================================= modal start ================================================= */}

      <div className="modal fade" id="insert-modal">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Generate Excise Report</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-sm-6">
                  <label>  Date </label>
                  <input type="date" value={date} onChange={(e) => setdate(e.target.value)} className="form-control" />
                </div>
              </div>
            </div>
            <div className="modal-footer justify-content-between">
              <button type="submit" onClick={() => handleSubmit()} className="btn btn-primary" data-dismiss="modal">Generate Report </button>
              <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="text-modal1">
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Print Exise Report</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span></button>
            </div>
            <div className="modal-body">
              <div className='row p-3' >
                {/* <div className='col-sm-4'>
                  <span>Name of the License</span>
                  <h6>MATOSHRI BAR</h6>
                </div>
                <div className='col-sm-4'>
                  <h4>FORM F.L.R. 1A/2A/3A</h4>
                </div> */}
                <div className='col-sm-4'>
                  <span>Date</span>
                  <h6>{moment(reportdate).format('DD/MM/YYYY')}</h6>
                </div>
              </div><hr />
              <table className='customers2'>
                <tr>
                  <th><label>Sr No</label></th>
                  <th style={{ textAlign: "left" }}>&nbsp;&nbsp;&nbsp;&nbsp;<label>Item</label></th>
                  <th><label>Opeing Balance</label></th>
                  <th><label>Purchase Stock</label></th>
                  <th><label>Sale Stock</label></th>
                  <th><label>Closing Balance</label></th>
                </tr>
                {
                  reportData.map((item, index) =>
                    <tr key={index}>
                      <td>{index + 1} </td>
                      <td style={{ textAlign: "left" }}>&nbsp;&nbsp;&nbsp;&nbsp;{item.data.prodcatname} {item.data.prodname} {item.data.unitname} </td>
                      <td>{item.data.openingunitstock} </td>
                      <td>{item.data.purchaseunitstock} </td>
                      <td>{item.data.saleunitstock} </td>
                      <td>{item.data.closingunitstock} </td>
                    </tr>
                  )
                }
              </table>
            </div>
            <div className="modal-footer justify-content-between">
              <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>


    </div>
  )
}
