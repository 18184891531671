import React, { useEffect, useState, useRef } from 'react'
import '.././App.css';
import Swal from 'sweetalert2';
import { auth, db } from '../firebase';
import { collection, addDoc, Timestamp, query, orderBy, onSnapshot, getDoc, getDocs, deleteDoc, doc, setDoc, where } from 'firebase/firestore'

let propid = localStorage.getItem('propid');
export default function ProductCategory() {
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });
  const [userData, setUserData] = useState();
  const [productCat, setproductCat] = useState([]);
  const [searchText, setsearchText] = useState("");
  const [catname, setcatname] = useState("");
  const [status, setstatus] = useState("YES");

  const [updocid, setupdocid] = useState("");
  const [upcatname, setupcatname] = useState("");
  const [upstatus, setupstatus] = useState("");

  useEffect(() => {
    if (searchText == '') {
      fetchData1()
    } else {
      fetchData2()
    }
  }, [searchText]);



  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setUserData(user);
    });
  });

  const fetchData1 = async () => {
    try {
      const q = query(collection(db, 'productcats'), where('propid', '==', propid))
      onSnapshot(q, (querySnapshot) => {
        setproductCat(querySnapshot.docs.map(doc => ({
          id: doc.id,
          data: doc.data()
        })));
      });
    } catch (error) {
      Toast.fire({
        icon: "error",
        title: error.message
      });
    }
  }

  const fetchData2 = async () => {
    try {
      const filteredData = productCat.filter((item) =>
        item.data.catname.toUpperCase().includes(searchText.toUpperCase())
      );
      setproductCat(filteredData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }



  const handleSubmit = async () => {
    await addDoc(collection(db, 'productcats'), {
      propid: propid,
      catname: catname,
      status: status,
      uid: userData.uid,
      created: Timestamp.now()
    });
    setcatname(""); setstatus("YES");
    Toast.fire({
      icon: "success",
      title: 'Product Category Added Successfully!!'
    });
  }

  const handleDelete = async (docid) => {
    console.log(docid);
    const taskDocRef = doc(db, 'productcats', docid)
    await deleteDoc(taskDocRef)
    Toast.fire({
      icon: "success",
      title: 'Product Category Deleted Successfully!!'
    });
    fetchData1();
  }

  const handleEdit = (docid, category, status) => {
    setupdocid(docid);
    setupcatname(category);
    setupstatus(status);
  }

  const handleUpdate = async () => {
    await setDoc(doc(db, "productcats", updocid), {
      catname: upcatname,
      propid: propid,
      status: upstatus,
      uid: userData.uid,
      created: Timestamp.now()
    });
    Toast.fire({
      icon: "success",
      title: 'Product Category Updated Successfully!!'
    });

    fetchData1();
  }

  return (
    <div>
      <div className="content-wrapper">
        <br />
        <section className="content">
          <div className="container-fluid">
            <button className="btn btn-secondary " style={{ float: "right" }} >|| Product Category ||</button>
            <button className="btn btn-info btn-sm" data-toggle="modal" data-target="#insert-modal">Add Product Category</button><br /><br />
            <div className="card card-primary card-outline ex1">
              <div className=" p-1">
                &nbsp;&nbsp;<input type="search" value={searchText} onChange={(e) => setsearchText(e.target.value)}
                  className='form-control col-sm-4' placeholder='Type here......' />&nbsp;
              </div>
              <div className="card-body table-responsive p-0" >
                <table className="table table-bordered table-hover table-striped table-head-fixed">
                  <thead>
                    <tr>
                      <th>Sr No</th>
                      <th>Product Category</th>
                      <th>Stock Calculate</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      productCat.map((item, num = 1) =>
                        <tr key={num + 1}>
                          <td width="80">{num + 1}</td>
                          <td>{item.data.catname}</td>
                          <td>{item.data.status}</td>
                          <td>
                            <a onClick={() => handleEdit(item.id, item.data.catname, item.data.status)} href='#0' data-toggle="modal" data-target="#text-modal1" className="btn btn-sm btn-success" > <i className="fa fa-edit"></i></a>&nbsp;
                            <a  onClick={() => { if (window.confirm('Are u sure?')) { handleDelete(item.id) }; }} className="btn btn-sm btn-danger" href='#0' > <i className="fa fa-trash"></i></a>
                          </td>
                        </tr>
                      )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* ============================================= modal start ================================================= */}

      <div className="modal fade" id="insert-modal">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Add Product Category</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-sm-6">
                  <label>  Product Category</label>
                  <input type="text" value={catname} onChange={(e) => setcatname(e.target.value)} className="form-control" />
                </div>
                <div className="col-sm-6">
                  <label>Stock Calculate</label>
                  <select value={status} onChange={(e) => setstatus(e.target.value)} className="form-control" >
                    <option value="YES">YES</option>
                    <option value="NO">NO</option>
                  </select>
                </div>
              </div><br />
            </div>
            <div className="modal-footer justify-content-between">
              <button type="button" onClick={() => handleSubmit()} className="btn btn-primary" data-dismiss="modal">Save </button>
              <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>


      <div className="modal fade" id="text-modal1">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Edit Product Category</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-sm-6">
                  <label> Product Category</label>
                  <input type="text" value={upcatname} onChange={(e) => setupcatname(e.target.value)} className="form-control" placeholder="Enter  Item Name ..." />
                </div>
                <div className="col-sm-6">
                  <label>Stock Calculate</label>
                  <select value={upstatus} onChange={(e) => setupstatus(e.target.value)} className="form-control" >
                    <option value="YES">YES</option>
                    <option value="NO">NO</option>
                  </select>
                </div>
              </div><br />
            </div>
            <div className="modal-footer justify-content-between">
              <button type="button" onClick={() => handleUpdate()} className="btn btn-primary" data-dismiss="modal">Update </button>
              <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
