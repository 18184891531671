import React, { useState, useEffect } from 'react'
import { auth } from '../firebase';

function Adminmenu() {
    const [user, setUser] = useState();
    useEffect(() => {
        document.title = 'PYRITE RESTO | DASHBOARD';
        auth.onAuthStateChanged((user) => {
            setUser(user);
        });
    });
    return (
        <div>
            <aside className="main-sidebar sidebar-dark-primary elevation-4">
                <a href="index3.html" className="brand-link">
                    <img src="dist/img/AdminLTELogo.png" alt="A" className="brand-image img-circle elevation-3" style={{ opacity: '.8' }} />
                    <span className="brand-text font-weight-light">PYRITE RESTO</span>
                </a>
                <div className="sidebar">
                    <nav className="mt-2">
                        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                            <li className="nav-item">
                                <a href="/home" className="nav-link">
                                    <i className="nav-icon fa fa-home" />
                                    <p>Home</p>
                                </a>
                            </li>
                            <li className="nav-header">Restaurents</li>
                            <li className="nav-item">
                                <a href="/add-restaurents" className="nav-link">
                                    <i className="nav-icon fa fa-cutlery" />
                                    <p>Add Restaurents</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="/restaurents-users" className="nav-link">
                                    <i className="nav-icon fa fa-users" />
                                    <p>Restaurent Users</p>
                                </a>
                            </li>
                        </ul>
                    </nav>
                    <br /><br /><br />
                </div>
            </aside>


        </div>
    )
}
export default Adminmenu