import React, { useEffect, useState, useRef } from 'react'
import '.././App.css';
import Swal from 'sweetalert2';
import { auth, db } from '../firebase';
import { collection, addDoc, Timestamp, query, orderBy, onSnapshot, getDoc, getDocs, deleteDoc, doc, setDoc, where, limit, updateDoc } from 'firebase/firestore'
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';

let propid = localStorage.getItem('propid');
export default function Propusers() {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });
    const [userData, setUserData] = useState();
    const [restaurents, setrestaurents] = useState([]);
    const [propusers, setpropusers] = useState([]);
    const [searchText, setsearchText] = useState("");

    const [loginemail, setloginemail] = useState("");
    const [loginpass, setloginpass] = useState("");

    const [username, setusername] = useState("");
    const [mobile, setmobile] = useState("");
    const [email, setemail] = useState("");
    const [password, setpassword] = useState("");
    const [role, setrole] = useState("Resto Receptionist");

    const [updocid, setupdocid] = useState("");
    const [uppropid, setuppropid] = useState("");
    const [upusername, setupusername] = useState("");
    const [upmobile, setupmobile] = useState("");
    const [uprole, setuprole] = useState("");

    const [userfetch, setuserfetch] = useState(true);


    useEffect(() => {
        if (searchText === '') {
            fetchData1()
        } else {
            fetchData2()
        }
    }, [searchText]);



    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            setUserData(user);
            userDataFetch()
        });
    });

    const userDataFetch = async () => {
        if (userData) {
            if (userfetch) {
                const docRef = doc(db, "users", userData.uid)
                const docSnap = await getDoc(docRef)
                if (docSnap.exists()) {
                    setloginemail(docSnap.data().email);
                    setloginpass(docSnap.data().password);
                }
            }
            setuserfetch(false)
        }
    }

    const fetchData1 = async () => {
        try {

            const q = query(collection(db, 'restaurents'))
            onSnapshot(q, (querySnapshot) => {
                setrestaurents(querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })));
            });
            const q1 = query(collection(db, 'users'), where('type', '==', 'Resto'))
            onSnapshot(q1, async (querySnapshot1) => {
                const combinedData = [];
                for (const doc1 of querySnapshot1.docs) {

                    combinedData.push({
                        id: doc1.id,
                        data: doc1.data(),
                    });
                }
                setpropusers(combinedData);
            });

        } catch (error) {
            Toast.fire({
                icon: "error",
                title: error.message
            });
        }
    }

    const fetchData2 = async () => {
        console.log(4545)
        try {
            if (propusers) {
                const filteredData = propusers.filter((item) =>
                    item.data.username.toUpperCase().includes(searchText.toUpperCase())
                );
                setpropusers(filteredData);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }


    const handleSubmit = async () => {
        try {
            if (!username || !email || !password) {
                Toast.fire({
                    icon: "error",
                    title: 'Please fill all the fields!!'
                });
                return;
            }

            await createUserWithEmailAndPassword(auth, email, password);
            const user = auth.currentUser;
            if (user) {
                await setDoc(doc(db, "users", user.uid), {
                    uid: user.uid,
                    propid: propid,
                    username: username,
                    mobile: mobile,
                    email: email,
                    password: password,
                    role: role,
                    type: "Resto",
                    loginid: userData.uid,
                    created: Timestamp.now()
                });

                auth.signOut();
                await signInWithEmailAndPassword(auth, loginemail, loginpass);
            }

            setusername("");
            setmobile("");
            setemail("");
            setpassword("");
            setrole("Resto Receptionist");

            Toast.fire({
                icon: "success",
                title: 'Restaurent Users Added Successfully!!'
            });

        } catch (error) {
            Toast.fire({
                icon: "error",
                title: error.message
            });
        }

    }

    const handleDelete = async (docid) => {
        const taskDocRef = doc(db, 'users', docid)
        await deleteDoc(taskDocRef)
        Toast.fire({
            icon: "success",
            title: 'User Deleted Successfully!!'
        });
        fetchData1();
    }

    const handleEdit = (id, propid, username, mobile, role) => {
        setupdocid(id);
        setupusername(username);
        setupmobile(mobile);
        setuprole(role);
        setuppropid(propid);
    }

    const handleUpdate = async () => {
        try {
            await updateDoc(doc(db, 'users', updocid), {
                username: upusername,
                mobile: upmobile,
                role: uprole,
            });

            Toast.fire({
                icon: "success",
                title: 'User Updated Successfully!!'
            });

            fetchData1();
        } catch (error) {
            console.log(error.message);
        }

    }



    return (
        <div>
            <div className="content-wrapper">
                <br />
                <section className="content">
                    <div className="container-fluid">
                        <button className="btn btn-secondary " style={{ float: "right" }} >|| Restaurent Users ||</button>
                        <button className="btn btn-info btn-sm" data-toggle="modal" data-target="#insert-modal">Add  Users</button><br /><br />
                        <div className="card card-primary card-outline ex1">
                            <div className=" p-1">
                                &nbsp;&nbsp;<input type="search" value={searchText} onChange={(e) => setsearchText(e.target.value)}
                                    className='form-control col-sm-4' placeholder='Type here......' />&nbsp;
                            </div>
                            <div className="card-body table-responsive p-0" >
                                <table className="table table-bordered table-hover table-striped table-head-fixed">
                                    <thead>
                                        <tr>
                                            <th>Sr No</th>
                                            <th>User Name</th>
                                            <th>Mobile</th>
                                            <th>Email</th>
                                            <th>Role</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            propusers.map((item, num = 1) =>
                                                <tr key={num + 1}>
                                                    <td width="80">{num + 1}</td>
                                                    <td>{item.data.username}</td>
                                                    <td>{item.data.mobile}</td>
                                                    <td>{item.data.email}</td>
                                                    <td>{item.data.role}</td>
                                                    <td>
                                                        <a onClick={() => handleEdit(item.id, item.data.propid, item.data.username, item.data.mobile, item.data.role)} href='#0' data-toggle="modal" data-target="#text-modal1" className="btn btn-sm btn-success" > <i className="fa fa-edit"></i></a>&nbsp;
                                                        {/* <a onClick={() => handleDelete(item.id)} className="btn btn-sm btn-danger" href='#0' > <i className="fa fa-trash"></i></a> */}
                                                    </td>
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* ============================================= modal start ================================================= */}

            <div className="modal fade" id="insert-modal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Add  Users </h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Role</label>
                                    <select value={role} onChange={(e) => setrole(e.target.value)} className="form-control" >
                                        <option value="Resto Receptionist">Resto Receptionist</option>
                                        <option value="Resto Admin">Resto Admin</option>
                                        <option value="Captain">Captain </option>
                                    </select>
                                </div>
                                <div className="col-sm-6">
                                    <label>User Name</label>
                                    <input type="text" value={username} onChange={(e) => setusername(e.target.value)} className="form-control" />
                                </div>
                                <div className="col-sm-6">
                                    <label>Mobile </label>
                                    <input type="text" value={mobile} onChange={(e) => setmobile(e.target.value)} maxLength={10} minLength={10} className="form-control" />
                                </div>
                                <div className="col-sm-6">
                                    <label>Email </label>
                                    <input type="text" value={email} onChange={(e) => setemail(e.target.value)} className="form-control" />
                                </div>
                                <div className="col-sm-6">
                                    <label>Password </label>
                                    <input type="text" value={password} onChange={(e) => setpassword(e.target.value)} className="form-control" />
                                </div>
                            </div><br />
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="submit" onClick={() => handleSubmit()} className="btn btn-primary" data-dismiss="modal">Save </button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal fade" id="text-modal1">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Edit  Users Details</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Role</label>
                                    <select value={uprole} onChange={(e) => setuprole(e.target.value)} className="form-control" >
                                        <option value="Resto Receptionist">Resto Receptionist</option>
                                        <option value="Resto Admin">Resto Admin</option>
                                        <option value="Captain">Captain </option>
                                    </select>
                                </div>
                                <div className="col-sm-6">
                                    <label>User Name</label>
                                    <input type="text" value={upusername} onChange={(e) => setupusername(e.target.value)} className="form-control" />
                                </div>
                                <div className="col-sm-6">
                                    <label>Mobile </label>
                                    <input type="text" value={upmobile} onChange={(e) => setupmobile(e.target.value)} maxLength={10} minLength={10} className="form-control" />
                                </div>
                            </div><br />
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" onClick={() => handleUpdate()} className="btn btn-primary" data-dismiss="modal">Update </button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
