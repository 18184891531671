import React, { useEffect, useState, useRef } from 'react'
import '.././App.css';
import Swal from 'sweetalert2';
import { auth, db } from '../firebase';
import { collection, query, orderBy, onSnapshot, getDoc, doc, where, } from 'firebase/firestore'
import moment from 'moment';
import Select from 'react-select'
import { useReactToPrint } from 'react-to-print';


let propid = localStorage.getItem('propid');
export default function CustomerwiseReport() {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });
    const [userData, setUserData] = useState();
    const [purchaseItesms, setpurchaseItesms] = useState([]);

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            setUserData(user);
        });
    });

    useEffect(() => {
        const today = new Date();
        const formattedDate = today.toISOString().split('T')[0];
        setfromdate(formattedDate);
        settodate(formattedDate);
        fetchData();
    }, []);

    const printRef = useRef();
    const [reportData, setreportData] = useState([]);
    const [customers, setcustomers] = useState([]);
    const [fromdate, setfromdate] = useState("");
    const [todate, settodate] = useState("");
    const [custtype, setcusttype] = useState("");
    const [totalamount, settotalamount] = useState("");
    const [paidamount, setpaidamount] = useState("");
    const [discount, setdiscount] = useState("");
    const [remaining, setremaining] = useState("");
    const [custid, setcustid] = useState("");
    const [customer, setcustomer] = useState("");



    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    })


    const fetchData = () => {
        const q = query(collection(db, 'restocustomers'))
        onSnapshot(q, (querySnapshot) => {
            setcustomers(querySnapshot.docs.map(doc => ({
                id: doc.id,
                data: doc.data(),
                label: doc.data().name,
                value: doc.id,
            })));
        });
    }

    const handleSelectItem1 = async (item) => {
        setcustomer(item);
        setcustid(item.id);
    };

    const getReport = () => {
        if (custid === "") {
            Toast.fire({ icon: "error", title: 'Please select customer' });
            return;
        }
        const q1 = query(collection(db, 'orders'), orderBy("created", "desc"),
            where('orderdate', '>=', fromdate), where('orderdate', '<=', todate), where('custid', '==', custid))
        onSnapshot(q1, async (querySnapshot1) => {
            const combinedData = [];
            let dealername = "";
            let totalamount = 0;
            let paidamount = 0;
            let discount = 0;
            let remaining = 0;
            for (const doc1 of querySnapshot1.docs) {
                if (doc1.data().custid) {
                    const docRef = doc(db, "restocustomers", doc1.data().custid);
                    const docSnap = await getDoc(docRef);
                    if (docSnap.data()) {
                        dealername = docSnap.data().name;
                    }
                    else {
                        dealername = "";
                    }
                }
                else {
                    dealername = "";
                }
                totalamount = parseFloat(totalamount) + parseFloat(doc1.data().totalamount);
                paidamount = parseFloat(paidamount) + parseFloat(doc1.data().paid);
                discount = parseFloat(discount) + parseFloat(doc1.data().discount);
                remaining = parseFloat(remaining) + parseFloat(doc1.data().remaining);

                settotalamount(totalamount);
                setpaidamount(paidamount);
                setdiscount(discount);
                setremaining(remaining);

                combinedData.push({
                    id: doc1.id,
                    data: doc1.data(),
                    dealername: dealername,
                });
            }
            console.log(combinedData);
            setreportData(combinedData);
        });
    }

    const handleView = (docid) => {
        const q = query(collection(db, 'orderitems'), where("orderid", "==", docid));
        onSnapshot(q, (querySnapshot2) => {
            setpurchaseItesms(querySnapshot2.docs.map(doc => ({
                id: doc.id,
                data: doc.data(),
            })));
        });
    }


    return (
        <div>
            <div className="content-wrapper">
                <section className="content">
                    <div className="container-fluid">
                        <button className="btn btn-secondary" style={{ float: "right" }} >|| Customer Wise Report  ||</button><br /><br />
                        <div className="card card-primary card-outline">
                            <div className="card-body table-responsive p-3" >
                                <div className='row '>
                                    <div className='col-sm-3'>
                                        <label>Select Customer  </label>
                                        <select className="form-control" value={custid} onChange={(e) => setcustid(e.target.value)} >
                                            <option></option>
                                            {
                                                customers.map((item, index) =>
                                                    <option key={index} value={item.id}>{item.data.name}</option>
                                                )
                                            }
                                        </select>
                                        {/* <Select options={customers} value={customer} onChange={handleSelectItem1} /> */}
                                    </div>
                                    <div className="col-sm-3">
                                        <label>From Date </label>
                                        <input type="date" className="form-control" style={{ height: 35, fontSize: 14 }} value={fromdate} onChange={(e) => setfromdate(e.target.value)} />
                                    </div>
                                    <div className="col-sm-3">
                                        <label>To Date </label>
                                        <input type="date" className="form-control" style={{ height: 35, fontSize: 14 }} value={todate} onChange={(e) => settodate(e.target.value)} />
                                    </div>
                                    <div className='col-sm-3'>
                                        <br />
                                        <button onClick={() => getReport()} className='btn btn-info'>Get Report</button>
                                    </div>
                                </div>
                            </div><br /><br /><br /><br />
                        </div>

                        <div className="card card-primary card-outline">
                            <div className="card-body table-responsive p-3" ref={printRef}>
                                {/* <center>
                                    <h6>MATOSHRI BAR</h6>
                                    <span>Dharashiv Road, Tuljapur, 413501</span><br />
                                    <span>Mobile No: 9921060146</span>
                                </center><hr /> */}
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <span><b>From Date : </b>{moment(fromdate).format('DD/MM/YYY')}</span>
                                    </div>
                                    <div className='col-sm-4 text-center'>
                                        <h6 style={{ textTransform: "uppercase" }}>Customer Report</h6>
                                    </div>
                                    <div className='col-sm-4'>
                                        <span style={{ float: "right" }}><b>To Date : </b>{moment(fromdate).format('DD/MM/YYY')}</span>
                                    </div>
                                </div>
                                <div className='row '>
                                    <table className='table table-striped'>
                                        <tr>
                                            <th>Sr No</th>
                                            <th>Date</th>
                                            <th>{custtype} Name</th>
                                            <th>Total Amount</th>
                                            <th>Paid Amount</th>
                                            <th>Discount</th>
                                            <th>Reamaining</th>
                                            <th>Action</th>
                                        </tr>
                                        {
                                            reportData.map((item, num = 1) =>
                                                <tr key={num + 1}>
                                                    <td width="80">{num + 1}</td>
                                                    <td>{moment(item.data.orderdate).format('DD-MM-YYYY')}</td>
                                                    <td>{item.dealername}</td>
                                                    <td>{item.data.totalamount}</td>
                                                    <td>{item.data.paid}</td>
                                                    <td>{item.data.discount}</td>
                                                    <td>{item.data.remaining}</td>
                                                    <td>
                                                        <a onClick={() => handleView(item.id)} className="btn btn-sm btn-success" href='#0' data-toggle="modal" data-target="#text-modal1"> <i className="fa fa-eye"></i></a>&nbsp;
                                                    </td>

                                                </tr>
                                            )}
                                        <tr>
                                            <td colSpan={3}><b>Total</b></td>
                                            <td><b>{totalamount}</b></td>
                                            <td><b>{paidamount}</b></td>
                                            <td><b>{discount}</b></td>
                                            <td><b>{remaining}</b></td>
                                            <td></td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <center>
                                <button onClick={handlePrint} className='btn btn-info'>Print Report</button>
                            </center><br />
                        </div>
                    </div>
                </section>
            </div>


            <div className="modal fade" id="text-modal1">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">View Order Items</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <table className='customers' style={{ width: "100%" }}>
                                <thead>
                                    <tr>
                                        <th><label>Item</label></th>
                                        <th><label>Rate</label></th>
                                        <th><label>Qty</label></th>
                                        <th><label>Total </label></th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        purchaseItesms.map((item, index) =>
                                            <tr key={index}>
                                                <td>{item.data.itemname}</td>
                                                <td>{item.data.rate}</td>
                                                <td>{item.data.quantity}</td>
                                                <td>{item.data.total}</td>
                                            </tr>

                                        )}
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}
