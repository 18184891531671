import React, { useEffect, useState, useRef } from 'react'
import '.././App.css';
import Swal from 'sweetalert2';
import { auth, db } from '../firebase';
import { collection, addDoc, Timestamp, query, orderBy, onSnapshot, getDoc, getDocs, deleteDoc, doc, setDoc, getAggregateFromServer, where, sum, updateDoc } from 'firebase/firestore'
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';

export default function CustomerAccount() {
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

  const printRef = useRef();

  const [userData, setUserData] = useState();
  const [customers, setcustomers] = useState([]);
  const [payhistory, setpayhistory] = useState([]);

  const [searchText, setsearchText] = useState("");
  const [name, setname] = useState("");
  const [mobile, setmobile] = useState("");
  const [address, setaddress] = useState("");

  const [totalamount, settotalamount] = useState("0");
  const [previuspaid, setpreviuspaid] = useState("0");
  const [currentpaid, setcurrentpaid] = useState("0");
  const [remaining, setremaining] = useState("0");
  const [remaining1, setremaining1] = useState("0");
  const [paytype, setpaytype] = useState("Cash");
  const [paidDate, setpaidDate] = useState("Cash");

  const [updocid, setupdocid] = useState("");

  useEffect(() => {
    if (searchText === '') {
      fetchData1()
      const today = new Date();
      const formattedDate = today.toISOString().split('T')[0];
      setpaidDate(formattedDate);
    } else {
      fetchData2()
    }
  }, [searchText]);



  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setUserData(user);
    });
  });

  const fetchData1 = async () => {
    try {

      const q1 = query(collection(db, 'restocustomers'), orderBy("name", "asc"))
      onSnapshot(q1, async (querySnapshot1) => {
        const combinedData = [];
        for (const doc1 of querySnapshot1.docs) {
          combinedData.push({
            id: doc1.id,
            data: doc1.data(),
          });
        }
        setcustomers(combinedData);
      });
    } catch (error) {
      Toast.fire({
        icon: "error",
        title: error.message
      });
    }
  }

  const fetchData2 = async () => {
    try {
      const filteredData = customers.filter((item) =>
        item.data.name.toUpperCase().includes(searchText.toUpperCase())
      );
      setcustomers(filteredData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }


  const handleReply = (docid, totalamount, padiamount, remaining) => {
    setupdocid(docid);
    settotalamount(totalamount);
    setpreviuspaid(padiamount);
    setremaining(remaining);
    setremaining1(remaining);

  }

  const handleUpdate = async () => {
    await addDoc(collection(db, 'customeracchistory'), {
      custid: updocid,
      purchaseNo: "",
      purchasedocid: "",
      paiddate: paidDate,
      totalamount: "",
      paid: parseFloat(currentpaid),
      remaining: remaining,
      paytype: paytype,
      status: 'Active',
      note: 'Paid from the Customer amount',
      uid: userData.uid,
      created: Timestamp.now()
    });

    
    const q1 = query(collection(db, 'customeracchistory'), where("custid", "==", updocid));
    const snapshot = await getAggregateFromServer(q1, {
      paidamount: sum('paid')
    });

    const q2 = query(collection(db, 'orders'), where("custid", "==", updocid));
    const snapshot1 = await getAggregateFromServer(q2, {
      grandtotal: sum('totalamount')
    });

    await updateDoc(doc(db, "restocustomers", updocid), {
      total: snapshot1.data().grandtotal,
      paid: snapshot.data().paidamount,
      remaining: parseFloat(snapshot1.data().grandtotal) - parseFloat(snapshot.data().paidamount),
    });


    setcurrentpaid(0);
    Toast.fire({
      icon: "success",
      title: 'Customer payment updated successfully!!'
    });

    fetchData1();
  }


  const finaltotal = () => {
    let remainingnew = parseFloat(remaining1) - parseFloat(currentpaid);
    if (remainingnew < 0) {
      Toast.fire({ icon: "error", title: 'Remaining should not be less than 0' });
      setcurrentpaid("");
      setremaining(remaining1);
      return;
    }
    if (currentpaid === '') {
      setremaining(remaining1);
    } else {
      setremaining(remainingnew);
    }
  }

  const handleView = (docid, name, mobile, address) => {
    setname(name);
    setmobile(mobile);
    setaddress(address);
    const q = query(collection(db, 'customeracchistory'), where("custid", "==", docid), orderBy('paiddate', 'desc'));
    onSnapshot(q, (querySnapshot2) => {
      setpayhistory(querySnapshot2.docs.map(doc => ({
        id: doc.id,
        data: doc.data(),
      })));
    });
  }

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  })

  const handleDelete = async (docid, custid) => {
    const taskDocRef = doc(db, 'customeracchistory', docid)
    await deleteDoc(taskDocRef)
    Toast.fire({
      icon: "success",
      title: 'Customer Payment Entry Deleted Successfully!!'
    });

    const q1 = query(collection(db, 'customeracchistory'), where("custid", "==", custid));
    const snapshot = await getAggregateFromServer(q1, {
      paidamount: sum('paid')
    });

    const q2 = query(collection(db, 'orders'), where("custid", "==", custid));
    const snapshot1 = await getAggregateFromServer(q2, {
      grandtotal: sum('totalamount')
    });

    await updateDoc(doc(db, "restocustomers", custid), {
      total: snapshot1.data().grandtotal,
      paid: snapshot.data().paidamount,
      remaining: parseFloat(snapshot1.data().grandtotal) - parseFloat(snapshot.data().paidamount),
    });


    fetchData1();
   
  }

  return (
    <div>
      <div className="content-wrapper">
        <br />
        <section className="content">
          <div className="container-fluid">
            <button className="btn btn-secondary " style={{ float: "right" }} >|| Customer  Account ||</button><br /><br />
            <div className="card card-primary card-outline ex1">
              <div className=" p-1">
                &nbsp;&nbsp;<input type="search" value={searchText} onChange={(e) => setsearchText(e.target.value)}
                  className='form-control col-sm-4' placeholder='Type here......' />&nbsp;
              </div>
              <div className="card-body table-responsive p-0" >
                <table className="table table-bordered table-hover table-striped table-head-fixed">
                  <thead>
                    <tr>
                      <th>Sr No</th>
                      <th>Customer Name</th>
                      <th>Mobile</th>
                      <th>Address</th>
                      <th>Total Amount</th>
                      <th>Discount </th>
                      <th>Paid </th>
                      <th>Remaining</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      customers.map((item, num = 1) =>
                        <tr key={num + 1}>
                          <td width="80">{num + 1}</td>
                          <td>{item.data.name}</td>
                          <td>{item.data.mobile}</td>
                          <td>{item.data.address}</td>
                          <td>{item.totalamount}</td>
                          <td>{item.data.total}</td>
                          <td>{item.data.paid}</td>
                          <td>{item.data.remaining}</td>
                          <td>
                            <a onClick={() => handleReply(item.id, item.data.total, item.data.paid, item.data.remaining)} href='#0' data-toggle="modal" data-target="#text-modal1" className="btn btn-sm btn-info" > <i className="fa fa-reply"></i></a>&nbsp;
                            <a onClick={() => handleView(item.id, item.data.name, item.data.mobile, item.data.address)} className="btn btn-sm btn-success" href='#0' data-toggle="modal" data-target="#text-modal2"> <i className="fa fa-eye"></i></a>&nbsp;
                          </td>
                        </tr>
                      )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* ============================================= modal start ================================================= */}

      <div className="modal fade" id="text-modal1">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Pay Customer Amount</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-sm-4">
                  <label> Total Amount</label>
                  <input type="text" className='form-control' style={{ height: 35, fontSize: 14 }} readOnly value={totalamount} />
                </div>
                <div className="col-sm-4">
                  <label>Previus Paid</label>
                  <input type="text" className='form-control' style={{ height: 35, fontSize: 14 }} value={previuspaid} readOnly />
                </div>
                <div className="col-sm-4">
                  <label>Current Paid</label>
                  <input type="text" className='form-control' style={{ height: 35, fontSize: 14 }} onKeyUp={() => finaltotal()} value={currentpaid} onChange={(e) => setcurrentpaid(e.target.value)} placeholder='Enter Paid Amount' />
                </div>
                <div className="col-sm-4 mt-2">
                  <label>Remaining</label>
                  <input type="text" className='form-control' style={{ height: 35, fontSize: 14 }} value={remaining} readOnly />
                </div>
                <div className="col-sm-4 mt-2">
                  <label>Pay Type</label>
                  <select className='form-control' style={{ height: 35, fontSize: 14 }} value={paytype} onChange={(e) => setpaytype(e.target.value)}>
                    <option value="Cash">Cash</option>
                    <option value="Bank">Bank</option>
                    <option value="RTGS">RTGS</option>
                    <option value="Online Pay">Online Pay</option>
                  </select>
                </div>
                <div className="col-sm-4 mt-2">
                  <label>Paid Date</label>
                  <input type="date" className="form-control" style={{ height: 35, fontSize: 14 }} value={paidDate} onChange={(e) => setpaidDate(e.target.value)} />
                </div>
              </div><br />
            </div>
            <div className="modal-footer justify-content-between">
              <button type="button" onClick={() => handleUpdate()} className="btn btn-primary" data-dismiss="modal">Add Payment </button>
              <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>


      <div className="modal fade" id="text-modal2">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Customer Payment History</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span></button>
            </div>
            <div className="modal-body" ref={printRef}>
              <div className='row border'>
                <div className='col-sm-6'>
                  <label>Customer Name :  </label> {name}
                </div>
                <div className='col-sm-6'>
                  <label>Customer Mobile : </label> {mobile}
                </div>
                <div className='col-sm-6'>
                  <label>Customer Address :  </label> {address}
                </div>
              </div>
              <table className='customers' style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th><label>Date</label></th>
                    <th><label>Total Amount</label></th>
                    <th><label>Discount</label></th>
                    <th><label>Paid Amount</label></th>
                    <th><label>Remaining</label></th>
                    <th><label>Pay Type</label></th>
                    <th><label>Note</label></th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>

                  {
                    payhistory.map((item, index) =>
                      <tr key={index}>
                        <td>{moment(item.data.paiddate).format("DD-MM-YYYY")}</td>
                        <td>{item.data.totalamount}</td>
                        <td>{item.data.discount}</td>
                        <td>{item.data.paid}</td>
                        <td>{item.data.remaining}</td>
                        <td>{item.data.paytype}</td>
                        <td>{item.data.note}</td>
                        <td>
                          <a onClick={() => handleDelete(item.id, item.data.custid)} className="btn btn-sm btn-danger" href='#0' > <i className="fa fa-trash"></i></a>
                        </td>
                      </tr>

                    )}
                </tbody>
              </table>
            </div>
            <div className="modal-footer justify-content-between">
              <button type="button" className="btn btn-info" onClick={handlePrint}>Print</button>
              <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
